class Matrix {
    constructor(rows, cols) {
      this.rows = rows;
      this.cols = cols;
      this.data = Array(this.rows).fill().map(() => Array(this.cols).fill(0));
    }
  
    copy() {
      let m = new Matrix(this.rows, this.cols);
      for (let i = 0; i < this.rows; i++) {
        for (let j = 0; j < this.cols; j++) {
          m.data[i][j] = this.data[i][j];
        }
      }
      return m;
    }
  
    static fromArray(arr) {
      return new Matrix(arr.length, 1).map((e, i) => arr[i]);
    }
  
    static subtract(a, b) {
      if (a.rows !== b.rows || a.cols !== b.cols) {
        console.log('Columns and Rows of A must match Columns and Rows of B.');
        console.log( "A : "+a.rows + 'x' + a.cols," === B : "+b.rows + 'x' + b.cols + ' static subtract');
        return;
      }
  
      // Return a new Matrix a-b
      return new Matrix(a.rows, a.cols)
        .map((_, i, j) => a.data[i][j] - b.data[i][j]);
    }
  
    toArray() {
      let arr = [];
      for (let i = 0; i < this.rows; i++) {
        for (let j = 0; j < this.cols; j++) {
          arr.push(this.data[i][j]);
        }
      }
      return arr;
    }
  
    randomize() {
      // eslint-disable-next-line no-unused-vars
      return this.map(e => Math.random() * 2 - 1);
    }
  
    add(n) {
      if (n instanceof Matrix) {
        if (this.rows !== n.rows || this.cols !== n.cols) {
          console.log('Columns and Rows of A must match Columns and Rows of B.');
          console.log( "A : "+this.rows + 'x' + this.cols," === B : "+n.rows + 'x' + n.cols + ' add');
          return;
        }
        return this.map((e, i, j) => e + n.data[i][j]);
      } else {
        return this.map(e => e + n);
      }
    }
  
    static transpose(matrix) {
      return new Matrix(matrix.cols, matrix.rows)
        .map((_, i, j) => matrix.data[j][i]);
    }
  
    static multiply(a, b) {
      // Matrix product
      if (a.cols !== b.rows) {
        console.log('Columns of A must match rows of B.');
        console.log( "A : "+a.rows + 'x' + a.cols," === B : "+b.rows + 'x' + b.cols + ' static multiply');
        return;
      }
  
      return new Matrix(a.rows, b.cols)
        .map((e, i, j) => {
          // Dot product of values in col
          let sum = 0;
          for (let k = 0; k < a.cols; k++) {
            sum += a.data[i][k] * b.data[k][j];
          }
          return sum;
        });
    }
  
    multiply(n) {
      if (n instanceof Matrix) {
        if (this.rows !== n.rows || this.cols !== n.cols) {
          console.log('Columns and Rows of A must match Columns and Rows of B.');
          console.log( "A : "+this.rows + 'x' + this.cols," === B : "+n.rows + 'x' + n.cols + ' multiply ');
          return;
        }
  
        // hadamard product
        return this.map((e, i, j) => e * n.data[i][j]);
      } else {
        // Scalar product
        return this.map(e => e * n);
      }
    }
  
    map(func) {
      // Apply a function to every element of matrix
      for (let i = 0; i < this.rows; i++) {
        for (let j = 0; j < this.cols; j++) {
          let val = this.data[i][j];
          this.data[i][j] = func(val, i, j);
        }
      }
      return this;
    }
  
    static map(matrix, func) {
      // Apply a function to every element of matrix
      return new Matrix(matrix.rows, matrix.cols)
        .map((e, i, j) => func(matrix.data[i][j], i, j));
    }
  
    print() {
      console.table(this.data);
      return this;
    }
  
    serialize() {
      return JSON.stringify(this);
    }
  
    static deserialize(data) {
      if (typeof data == 'string') {
        data = JSON.parse(data);
      }
      let matrix = new Matrix(data.rows, data.cols);
      matrix.data = data.data;
      return matrix;
    }
  }
  
  if (typeof module !== 'undefined') {
    module.exports = Matrix;
  }
  
  
  class ActivationFunction {
    constructor(func, dfunc) {
      this.func = func;
      this.dfunc = dfunc;
    }
  }
  
  let sigmoid = new ActivationFunction(
    x => 1 / (1 + Math.exp(-x)),
    y => y * (1 - y)
    );
  
  // eslint-disable-next-line no-unused-vars
  let tanh = new ActivationFunction(
    x => Math.tanh(x),
    y => 1 - (y * y)
    );

    // eslint-disable-next-line no-unused-vars
    let relu = new ActivationFunction(
      x => Math.max(0, x),  // Função de ativação
      y => y > 0 ? 1 : 0    // Derivada para backpropagation
    );
  
  
  class NeuralNetwork {
    /*
    * if first argument is a NeuralNetwork the constructor clones it
    * USAGE: cloned_nn = new NeuralNetwork(to_clone_nn);
    */
    constructor(in_nodes, hid_nodes, out_nodes) {
      if (in_nodes instanceof NeuralNetwork) {
        let a = in_nodes;
        this.input_nodes = a.input_nodes;
        this.hidden_nodes = a.hidden_nodes;
        this.output_nodes = a.output_nodes;
      
        // Copiar as matrizes de pesos e vieses
        this.weights_ih = a.weights_ih.copy();
      
        // Iterar sobre o array de weights_hh e copiar cada matriz
        this.weights_hh = a.weights_hh.map(weightMatrix => weightMatrix.copy());
      
        this.weights_ho = a.weights_ho.copy();
      
        this.bias_ih = a.bias_ih.copy();
      
        // Iterar sobre o array de bias_hh e copiar cada matriz
        this.bias_hh = a.bias_hh.map(biasMatrix => biasMatrix.copy());
      
        this.bias_ho = a.bias_ho.copy();
      }
       else {
        this.input_nodes = in_nodes;
        this.hidden_nodes = hid_nodes;
        this.output_nodes = out_nodes;
  
        let first_hidden = 0;
        let last_hidden  = hid_nodes.length-1;
  
        this.weights_ih = new Matrix(this.hidden_nodes[first_hidden], this.input_nodes);
        this.weights_ho = new Matrix(this.output_nodes, this.hidden_nodes[last_hidden]);
        this.weights_hh = [];
        for(let i=0;i<last_hidden;i++)
        {
          this.weights_hh[i] = new Matrix(this.hidden_nodes[i+1], this.hidden_nodes[i]);
          this.weights_hh[i].randomize();
        }
        this.weights_ih.randomize();
        this.weights_ho.randomize();
        
        this.bias_ih = new Matrix(this.hidden_nodes[first_hidden], 1);
        this.bias_ho = new Matrix(this.output_nodes, 1);
        this.bias_hh = [];
        for(let i=0;i<last_hidden;i++)
        {
          this.bias_hh[i] = new Matrix(hid_nodes[i+1], 1);
          this.bias_hh[i].randomize();
        }
        
        this.bias_ih.randomize();
        this.bias_ho.randomize();
  
      }
  
      // TODO: copy these as well
      this.setLearningRate();
      this.setActivationFunction();
  
  
    }
  
    predict(input_array) {
      // Generating the Hidden Outputs
      let inputs = Matrix.fromArray(input_array);
      let hidden = Matrix.multiply(this.weights_ih, inputs);
      hidden.add(this.bias_ih);
      // activation function!
      hidden.map(this.activation_function.func);
  
      for(let i=0;i<this.weights_hh.length;i++){
       hidden = Matrix.multiply(this.weights_hh[i], hidden);
       hidden.add(this.bias_hh[i]);
       hidden.map(this.activation_function.func);
     }
  
      // Generating the output's output!
      let output = Matrix.multiply(this.weights_ho, hidden);
      output.add(this.bias_ho);
      output.map(this.activation_function.func);
  
      // Sending back to the caller!
      return output.toArray();
    }
  
    setLearningRate(learning_rate = 0.1) {
      this.learning_rate = learning_rate;
    }
  
    setActivationFunction(func = sigmoid) {
      this.activation_function = func;
    }
  
  
    train(input_array, target_array) {
  
      // Generating the Hidden Outputs
      let inputs = Matrix.fromArray(input_array);
      let hidden = Matrix.multiply(this.weights_ih, inputs);
      hidden.add(this.bias_ih);
      // activation function!
      hidden.map(this.activation_function.func);
      for(let i=0;i<this.weights_hh.length;i++){
        hidden = Matrix.multiply(this.weights_hh[i],hidden);
        hidden.add(this.bias_hh[i]);
        hidden.map(this.activation_function.func);
      }
  
      // Generating the output's output!
      let outputs = Matrix.multiply(this.weights_ho, hidden);
      outputs.add(this.bias_ho);
      outputs.map(this.activation_function.func);
  
      // Convert array to matrix object
      let targets = Matrix.fromArray(target_array);
  
      // Calculate the error
      // ERROR = TARGETS - OUTPUTS
      let output_errors = Matrix.subtract(targets, outputs);
  
      // let gradient = outputs * (1 - outputs);
      // Calculate gradient
      let gradients = Matrix.map(outputs, this.activation_function.dfunc);
  
      gradients.multiply(output_errors);
      gradients.multiply(this.learning_rate);
  
      // Calculate deltas
      let hidden_T = Matrix.transpose(hidden);
      let weight_ho_deltas = Matrix.multiply(gradients, hidden_T);
      // Adjust the weights by deltas
      this.weights_ho.add(weight_ho_deltas);
      // Adjust the bias by its deltas (which is just the gradients)
      this.bias_ho.add(gradients);
  
      // Calculate the hidden layer errors
      let who_t = Matrix.transpose(this.weights_ho);
      let hidden_errors = Matrix.multiply(who_t, output_errors);
  
      // Calculate hidden gradient
      let hidden_gradient = Matrix.map(hidden, this.activation_function.dfunc);
      hidden_gradient.multiply(hidden_errors);
      hidden_gradient.multiply(this.learning_rate);
      
      if (this.weights_hh.length>=1)
  
      // Calculate aditional hidden layers
    for(let i=this.weights_hh.length-1;i>=0;i--){
  
      if (i==0) {  
        hidden = Matrix.multiply(this.weights_ih, inputs); 
      } else  {
       hidden = Matrix.multiply(this.weights_hh[i-1], hidden);
       hidden.add(this.bias_hh[i-1]);
       hidden.map(this.activation_function.func);
     }
  
     let hidden_T = Matrix.transpose(hidden);
     let weight_hh_deltas = Matrix.multiply(hidden_gradient,hidden_T);
  
     this.weights_hh[i].add(weight_hh_deltas);
     this.bias_hh[i].add(hidden_gradient);
  
     who_t = Matrix.transpose(this.weights_hh[i]);
     let hidden_hh_error = Matrix.multiply(who_t,hidden_errors);
  
     hidden_gradient = Matrix.map(hidden,this.activation_function.dfunc);
     hidden_gradient.multiply(hidden_hh_error);
     hidden_gradient.multiply(this.learning_rate);
   }
  
      // Calcuate input->hidden deltas
      let inputs_T = Matrix.transpose(inputs);
      let weight_ih_deltas = Matrix.multiply(hidden_gradient, inputs_T);
      this.weights_ih.add(weight_ih_deltas);
      // Adjust the bias by its deltas (which is just the gradients)
      this.bias_ih.add(hidden_gradient);
      
    }
  
    serialize() {
      return JSON.stringify(this);
    }
  
    static deserialize(data) {
      if (typeof data == 'string') {
        data = JSON.parse(data);
      }
      let nn = new NeuralNetwork(data.input_nodes, data.hidden_nodes, data.output_nodes);
      nn.weights_ih = Matrix.deserialize(data.weights_ih);
      nn.weights_ho = Matrix.deserialize(data.weights_ho);
      nn.bias_ih = Matrix.deserialize(data.bias_ih);
      nn.bias_ho = Matrix.deserialize(data.bias_ho);
      nn.learning_rate = data.learning_rate;
      return nn;
    }
  
  
    // Adding function for neuro-evolution
    copy() {
      return new NeuralNetwork(this);
    }
  
    // Accept an arbitrary function for mutation
    mutate(func) {
      this.weights_ih.map(func);
      this.weights_ho.map(func);
      this.bias_ih.map(func);
      this.bias_ho.map(func);
    }
  
  
  
  }
module.exports = {
    NeuralNetwork
}