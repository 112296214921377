import * as THREE from "three";

class SoftBody {
  constructor(scene, mesh, mass, stiffness, damping) {
    this.scene = scene;
    this.mesh = mesh;
    this.particles = []; // Cada vértice da malha será tratado como uma partícula
    this.springs = []; // Conexões entre partículas para simular elasticidade
    this.mass = mass;
    this.stiffness = stiffness;
    this.damping = damping;
    this.initParticles();
    this.initSprings();
    scene.add(mesh);
  }

  initParticles() {
    // Inicializar partículas baseadas nos vértices do mesh
    const vertices = this.mesh.geometry.attributes.position.array;
    for (let i = 0; i < vertices.length; i += 3) {
      this.particles.push({
        position: new THREE.Vector3(
          vertices[i],
          vertices[i + 1],
          vertices[i + 2]
        ),
        velocity: new THREE.Vector3(),
        force: new THREE.Vector3(),
        mass: this.mass / this.particles.length,
      });
    }
  }

  initSprings() {
    const positions = this.mesh.geometry.attributes.position.array;

    for (let i = 0; i < positions.length; i += 9) {
      // Cada triângulo consiste em três vértices
      const p1 = this.particles[i / 3];
      const p2 = this.particles[i / 3 + 1];
      const p3 = this.particles[i / 3 + 2];

      // Certifica-se de que as partículas existem
      if (p1 && p2) {
        this.springs.push({
          p1,
          p2,
          restLength: p1.position.distanceTo(p2.position),
        });
      }
      if (p2 && p3) {
        this.springs.push({
          p2,
          p3,
          restLength: p2.position.distanceTo(p3.position),
        });
      }
      if (p3 && p1) {
        this.springs.push({
          p3,
          p1,
          restLength: p3.position.distanceTo(p1.position),
        });
      }
    }
  }

  simulate(dt) {
    // Atualizar forças, velocidades e posições das partículas
    this.applyForces();
    this.integrate(dt);
    this.updateMesh();
  }

  applyForces() {
    // Aplicar forças internas (molas) e externas (gravidade)
    this.particles.forEach((p) => {
      if (!p) return; // Verificação adicional para evitar erros em partículas indefinidas
      p.force.set(0, -9.8 * p.mass, 0); // Gravidade
    });

    this.springs.forEach((spring) => {
      const { p1, p2 } = spring;
      if (!p1 || !p2) return; // Verifica se as partículas existem
      const delta = p2.position.clone().sub(p1.position); // Direção da mola
      const length = delta.length(); // Comprimento atual da mola
      const force = delta
        .normalize()
        .multiplyScalar((length - spring.restLength) * this.stiffness);
      p1.force.add(force); // Adiciona a força na primeira partícula
      p2.force.sub(force); // Aplica força oposta na segunda partícula
    });
  }

  integrate(dt) {
    // Integrar posições e velocidades
    this.particles.forEach((p) => {
      p.velocity.add(p.force.clone().multiplyScalar(dt / p.mass)); // Velocidade += força / massa * dt
      p.velocity.multiplyScalar(1 - this.damping); // Amortecimento
      p.position.add(p.velocity.clone().multiplyScalar(dt)); // Posição += velocidade * dt
    });
  }

  updateMesh() {
    // Atualizar geometria do mesh
    const vertices = this.mesh.geometry.attributes.position.array;
    this.particles.forEach((p, i) => {
      vertices[i * 3] = p.position.x;
      vertices[i * 3 + 1] = p.position.y;
      vertices[i * 3 + 2] = p.position.z;
    });
    this.mesh.geometry.attributes.position.needsUpdate = true;
  }
}

class RigidBodySimulator {
  constructor(scene, timeStepSize, gravity) {
    this.scene = scene;
    this.gravity = gravity.clone();
    this.dt = timeStepSize;
    this.rigidBodies = [];
    this.softBodies = [];
  }

  addRigidBody(body) {
    this.rigidBodies.push(body);
  }

  addSoftBody(body) {
    this.softBodies.push(body);
  }

  simulate() {
    this.rigidBodies.forEach((rb) => rb.integrate(this.dt, this.gravity));
    this.softBodies.forEach((sb) => sb.simulate(this.dt));
    this.softBodies.forEach((sb) => {
      this.rigidBodies.forEach((rb) => detectSoftBodyHardBodyCollision(sb, rb));
    });
  }
}

const THRESHOLD = 0.005; // 50% do tamanho médio das partículas
const COLLISION_STRENGTH = 50; // Ajustado para reação perceptível

function detectSoftBodyHardBodyCollision(softBody, rigidBody) {
  softBody.particles.forEach((particle) => {
    const closestPoint =
      rigidBody.meshes[0].geometry.boundingBox.closestPointToPoint(
        particle.position
      );
    const penetration = particle.position.clone().sub(closestPoint);
    if (penetration.length() < THRESHOLD) {
      // Se estiver muito próximo
      const force = penetration.normalize().multiplyScalar(-COLLISION_STRENGTH);
      particle.force.add(force);
      rigidBody.applyCorrection(0, force.negate(), particle.position);
    }
  });
}

export { SoftBody, RigidBodySimulator, detectSoftBodyHardBodyCollision };
