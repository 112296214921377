import * as THREE from "three";

class Agent {
  constructor(scenario, vertex = null) {
      this.position = new THREE.Vector3(0,0,0);  // A posição atual do agente

      // Inicializa o agente em uma posição aleatória no cenário
      const randomIndex = Math.floor(Math.random() * scenario.vertices.length);
      this.position.copy(scenario.vertices[randomIndex]);

      if (vertex !== null) this.position.copy(vertex);

      this.key = `${this.position.x},${this.position.y},${this.position.z}`;
      this.curvature = scenario.curvatureMap.get(this.key) || 0;
      this.height = scenario.heightMap.get(this.key) || 0;
      this.density = scenario.vertexDensityMap.get(this.key) || 0;
      this.gradient = scenario.gradientMap.get(this.key) || 0;
      this.maxillary = scenario.maxillary;

      this.fitness = 0;  // Fitness inicial do agente

      // Armazenar apenas os min e max recebidos do cenário
      this.minX = scenario.minX;
      this.maxX = scenario.maxX;
      this.minY = scenario.minY;
      this.maxY = scenario.maxY;
      this.minZ = scenario.minZ;
      this.maxZ = scenario.maxZ;

      const normalizedPosition = this.normalizePosition();

      this.inputs = [
        this.curvature,
        this.height,
        this.density,
        this.gradient,
        this.maxillary,
        ...normalizedPosition  
      ];
      
  }

  dispose() {
    this.neuralNetwork = null;
    this.position = null;
  }

  normalizePosition() {
    // Normalizar a posição usando os min e max recebidos no construtor
    const normX = (this.position.x - this.minX) / (this.maxX - this.minX);
    const normY = (this.position.y - this.minY) / (this.maxY - this.minY);
    const normZ = (this.position.z - this.minZ) / (this.maxZ - this.minZ);

    return [normX, normY, normZ];
  }

  predictPosition() {
      this.prediction = this.neuralNetwork.predict(this.inputs);
      return this.prediction;
  }
}

function trainAgents(neuralNetwork, scenario) {
  for (let i = 0; i < scenario.vertices.length; i++) {
    // Criar um novo agente
    const agent = new Agent(scenario, [scenario.vertices[i]]);

    // Coletar os inputs do agente (curvatura, altura, etc.)
    const inputs = agent.inputs;

    // Coletar o target usando a key do agente
    const target = scenario.classificationMap.get(agent.key); // 0 = Não Borda, 1 = Borda

    // Converta o target em um array de saídas esperadas
    const targetArray = [0, 0];
    targetArray[target] = 1;  // Define a saída esperada com 1 para a classe correta

    // Treinar a rede com os inputs do agente e o target
    neuralNetwork.train(inputs, targetArray);

    // Limpa o agente após o uso
    agent.dispose();
  }
}

function testAgents(neuralNetwork, scenario, mesh) {
  // Obter o array de posições do mesh
  const positions = mesh.geometry.attributes.position.array;
  const colors = new Float32Array(positions.length); // Array para armazenar as cores

  let hits = 0;

  scenario.vertices.forEach((vertex, i) => {
    // Criar um agente com o vértice específico
    const agent = new Agent(scenario, vertex);
    const inputs = agent.inputs;

    // Fazer a predição com a rede treinada
    const prediction = neuralNetwork.predict(inputs);

    const key = `${agent.position.x},${agent.position.y},${agent.position.z}`;
    const trueClass = scenario.classificationMap.get(key); // O valor verdadeiro
    const maxValue = Math.max(...prediction);
    const predictedClass = prediction.indexOf(maxValue);

    // Colorir o vértice com base na classe predita
    let r = 0, g = 0, b = 0;

    if (predictedClass === 0) {  // Classe 0: Azul (dente)
      b = 1.0;
    } else if (predictedClass === 1) {  // Classe 1: Verde (borda)
      g = 1.0;
    } else if (predictedClass === 2) {  // Classe 2: Vermelho (gengiva)
      r = 1.0;
    }

    // Aplicar as cores no array de cores
    colors[i * 3] = r;     // R
    colors[i * 3 + 1] = g; // G
    colors[i * 3 + 2] = b; // B

    // Contar acertos
    if (predictedClass === trueClass) {
      hits++;
    }

    agent.dispose(); // Limpar o agente após o teste
  });

  // Atualizar as cores do mesh
  mesh.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
  mesh.geometry.attributes.color.needsUpdate = true;

  console.warn(`Acertei ${hits} predições de ${positions.length/3}`);

  // Definir o material do mesh para usar as cores dos vértices
  mesh.material = new THREE.MeshBasicMaterial({ vertexColors: true,
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide });
}


export { Agent, trainAgents, testAgents };
