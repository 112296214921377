import * as tf from "@tensorflow/tfjs";
import * as THREE from "three";
//import * as tfvis from "@tensorflow/tfjs-vis";

function prepareData(scenario, inputs = null, labels = null) {
  // Se inputs e labels já estiverem fornecidos, converta-os diretamente em tensores
  if (inputs && labels) {
    const inputTensor = tf.tensor2d(inputs);
    const labelTensor = tf.tensor2d(labels);
    return { inputTensor, labelTensor, inputs, labels };
  }

  // Caso contrário, gere inputs e labels a partir do scenario
  inputs = [];
  labels = [];

  // Iterar sobre todos os vértices no cenário
  scenario.vertices.forEach((vertex) => {
    const key = `${vertex.x},${vertex.y},${vertex.z}`;

    // Coletar os dados de entrada para cada vértice
    const curvature = scenario.curvatureMap.get(key) || 0;
    const height = scenario.heightMap.get(key) || 0;
    const density = scenario.vertexDensityMap.get(key) || 0;
    const gradient = scenario.gradientMap.get(key) || 0;
    const maxillary = scenario.maxillary;
    const distance = scenario.distanceMap.get(key) || 0;

    // Normalizar a posição
    // const normX = (vertex.x - scenario.minX) / (scenario.maxX - scenario.minX);
    // const normY = (vertex.y - scenario.minY) / (scenario.maxY - scenario.minY);
    // const normZ = (vertex.z - scenario.minZ) / (scenario.maxZ - scenario.minZ);

    // Adicionar os inputs
    inputs.push([curvature, height, density, gradient, maxillary, distance]);

    // Coletar o rótulo correspondente (0 ou 1)
    const classification = scenario.classificationMap.get(key);
    labels.push(classification === 1 ? [1, 0] : [0, 1]); // One-hot encoding
  });

  // Converter para tensores
  const inputTensor = tf.tensor2d(inputs);
  const labelTensor = tf.tensor2d(labels);

  return { inputTensor, labelTensor, inputs, labels };
}

function splitData(inputTensor, labelTensor, splitRatio = 0.8) {
  const numSamples = inputTensor.shape[0];
  const numTrainSamples = Math.floor(numSamples * splitRatio);

  // Divida os dados em treinamento e teste com base em um índice
  const trainX = inputTensor.slice(
    [0, 0],
    [numTrainSamples, inputTensor.shape[1]]
  );
  const testX = inputTensor.slice(
    [numTrainSamples, 0],
    [numSamples - numTrainSamples, inputTensor.shape[1]]
  );

  const trainY = labelTensor.slice(
    [0, 0],
    [numTrainSamples, labelTensor.shape[1]]
  );
  const testY = labelTensor.slice(
    [numTrainSamples, 0],
    [numSamples - numTrainSamples, labelTensor.shape[1]]
  );

  return { trainX, trainY, testX, testY };
}


function createModel() {
  checkWebGLBackend();

  const model = tf.sequential();

  // Camada de entrada -> Camada oculta com 24 neurônios e função de ativação 'relu'
  model.add(
    tf.layers.dense({ inputShape: [6], units: 128, activation: "relu" })
  );

  // Nova camada oculta com 24 neurônios e função de ativação 'relu'
  model.add(tf.layers.dense({ units: 256, activation: "relu" }));
  model.add(tf.layers.dropout({ rate: 0.15 })); // 30% de dropout

  // Nova camada oculta com 24 neurônios e função de ativação 'relu'
  model.add(tf.layers.dense({ units: 128, activation: "relu" }));

  // Camada de saída com 2 neurônios (softmax para classificação)
  model.add(tf.layers.dense({ units: 2, activation: "softmax" }));

  // Compilar o modelo
  model.compile({
    optimizer: tf.train.adam(0.01),
    loss: "categoricalCrossentropy",
    metrics: ["accuracy"],
  });

  return model;
}

async function checkWebGLBackend() {
  await tf.setBackend("webgl");
  await tf.ready();

  const backend = tf.getBackend();
  console.log(`Backend atual: ${backend}`);

  if (backend === "webgl") {
    console.log("WebGL está sendo usado como backend para aceleração com GPU.");
  } else {
    console.log("O backend atual não é WebGL. Backend atual:", backend);
  }
}

// Função para calcular precisão, revocação e F1-score por classe
function calculateMetrics(labels, predictions) {
  const numClasses = labels[0].length;
  const classMetrics = Array.from({ length: numClasses }, () => ({
    truePositive: 0,
    falsePositive: 0,
    falseNegative: 0,
  }));

  labels.forEach((label, i) => {
    const predictedClass = predictions[i].indexOf(Math.max(...predictions[i]));
    const actualClass = label.indexOf(Math.max(...label));

    if (predictedClass === actualClass) {
      classMetrics[actualClass].truePositive++;
    } else {
      classMetrics[predictedClass].falsePositive++;
      classMetrics[actualClass].falseNegative++;
    }
  });

  // Calcular precisão, revocação e F1-score para cada classe
  const results = classMetrics.map((metric) => {
    const precision =
      metric.truePositive / (metric.truePositive + metric.falsePositive || 1);
    const recall =
      metric.truePositive / (metric.truePositive + metric.falseNegative || 1);
    const f1Score = (2 * precision * recall) / (precision + recall || 1);
    return { precision, recall, f1Score };
  });

  return results;
}

// Função para calcular matriz de confusão
// eslint-disable-next-line no-unused-vars
function calculateConfusionMatrix(labels, predictions) {
  const numClasses = labels[0].length;
  const matrix = Array.from({ length: numClasses }, () =>
    Array(numClasses).fill(0)
  );

  labels.forEach((label, i) => {
    const actualClass = label.indexOf(Math.max(...label));
    const predictedClass = predictions[i].indexOf(Math.max(...predictions[i]));
    matrix[actualClass][predictedClass]++;
  });

  return matrix;
}

// Define a classe ReduceLROnPlateau
class ReduceLROnPlateau {
  constructor(optimizer, options) {
    this.optimizer = optimizer; // Referência ao otimizador
    this.monitor = options.monitor || "val_loss"; // Métrica a ser monitorada
    this.factor = options.factor || 0.5; // Fator de redução
    this.patience = options.patience || 5; // Paciência
    this.minLR = options.minLR || 1e-6; // Limite mínimo de LR

    this.bestValue = Infinity; // Melhor valor de perda observado
    this.wait = 0; // Contador para paciência
  }

  async onEpochEnd(epoch, logs) {
    const currentValue = logs[this.monitor]; // Obter a métrica monitorada
    if (currentValue < this.bestValue) {
      this.bestValue = currentValue;
      this.wait = 0; // Resetar o contador de paciência
    } else {
      this.wait++; // Incrementar o contador de paciência
      if (this.wait >= this.patience) {
        const currentLR = this.optimizer.learningRate;
        const newLR = Math.max(currentLR * this.factor, this.minLR);
        this.optimizer.learningRate = newLR;
        console.log(
          `Learning rate reduzida para ${newLR} na época ${epoch + 1}`
        );
        this.wait = 0; // Resetar o contador
      }
    }
  }
}

// Função de treinamento
async function trainModel(model, inputTensor, labelTensor, fraction = 1.0, app) {
  const batchSize = 8192;
  const epochs = 250;
  const numSamples = Math.floor(inputTensor.shape[0] * fraction);
  const inputSubset = inputTensor.slice(
    [0, 0],
    [numSamples, inputTensor.shape[1]]
  );
  const labelSubset = labelTensor.slice(
    [0, 0],
    [numSamples, labelTensor.shape[1]]
  );
  const classWeights = calculateClassWeights(labelSubset);

  let epochTimes = []; // Armazena os tempos de cada época
  let startTime = Date.now();

  const customCallbacks = {
    onEpochBegin: async () => {
      startTime = Date.now();
    },
    onEpochEnd: async (epoch, logs) => {
      // Calcule o tempo da época atual
      const endTime = Date.now();
      const epochTime = (endTime - startTime) / 1000; // Em segundos
      epochTimes.push(epochTime);

      // Calcule a média de tempo por época
      const averageEpochTime =
        epochTimes.reduce((a, b) => a + b, 0) / epochTimes.length;

      // Estime o tempo restante com base nas épocas restantes
      const remainingEpochs = epochs - (epoch + 1);
      const estimatedRemainingTime = averageEpochTime * remainingEpochs;

      const estimatedEndTime = Date.now() + estimatedRemainingTime * 1000; // Convertemos para milissegundos
      const estimatedEndDate = new Date(estimatedEndTime).toLocaleString();

      // Obter previsões e rótulos verdadeiros
      const predictions = await model.predict(inputSubset).array();
      const labels = labelSubset.arraySync();

      // Calcular precisão, revocação e F1-score
      const metrics = calculateMetrics(labels, predictions);
      metrics.forEach((metric, classIndex) => {
        app.trainingMetrics[classIndex] = {
          classIndex: classIndex,
          epoch: epoch + 1,
          totalEpochs: epochs,
          loss: logs.loss.toFixed(6),
          accuracy: logs.acc,
          precision: metric.precision,
          recall: metric.recall,
          f1Score: metric.f1Score,
          epochTime: epochTime, // Tempo de treinamento da época atual
          averageEpochTime: averageEpochTime, // Tempo médio por época
          estimatedRemainingTime: estimatedRemainingTime, // Tempo estimado restante
          estimatedEndDate: estimatedEndDate,
        };
      });

      // Redução da learning rate
      await reduceLR.onEpochEnd(epoch, logs);
    },
    onTrainEnd: async () => {
      console.log("Treinamento concluído. Fechando tfvis...");
      // tfvis.visor().close(); // Fecha o visor tfvis ao final do treinamento
    },
  };

  // Define o otimizador e o callback ReduceLROnPlateau
  const optimizer = model.optimizer;
  const reduceLR = new ReduceLROnPlateau(optimizer, {
    monitor: "val_loss", // Métrica que estamos monitorando
    factor: 0.995, // Reduz pela metade
    patience: 5, // Espera 5 épocas sem melhora
    minLR: 1e-6, // Limite mínimo
  });

  // Combine os callbacks
  return await model.fit(inputSubset, labelSubset, {
    batchSize,
    epochs,
    shuffle: true,
    classWeight: classWeights,
    callbacks: [customCallbacks],
  });
}


async function evaluateModel(model, inputTensor, labelTensor) {
  const result = await model.evaluate(inputTensor, labelTensor);
  const loss = result[0].dataSync()[0]; // A perda final
  const accuracy = result[1].dataSync()[0]; // A acurácia final

  console.log(`Final Loss: ${loss}, Final Accuracy: ${accuracy}`);
}

function testModel(model, inputTensor, labelTensor) {
  const predictions = model.predict(inputTensor);

  // Para cada predição, comparar com o rótulo verdadeiro
  predictions.array().then((preds) => {
    preds.forEach((pred, index) => {
      const predictedClass = pred.indexOf(Math.max(...pred));
      const actualClass = labelTensor.arraySync()[index].indexOf(1);

      console.log(`Predicted: ${predictedClass}, Actual: ${actualClass}`);
    });
  });
}

// eslint-disable-next-line no-unused-vars
function testModelMesh_(model, inputTensor, mesh) {
  // Obter o array de posições do mesh
  const positions = mesh.geometry.attributes.position.array;
  const colors = new Float32Array(positions.length); // Array para armazenar as cores

  // Fazer predições com o modelo TensorFlow
  const predictions = model.predict(inputTensor);

  // Como predictions é um tensor, precisamos convertê-lo em um array
  predictions.array().then((preds) => {
    preds.forEach((pred, index) => {
      const predictedClass = pred.indexOf(Math.max(...pred));

      // Colorir o vértice com base na classe predita
      let r = 0,
        g = 0,
        b = 0;

      if (predictedClass === 0) {
        // Classe 0: Azul (dente)
        g = 1.0;
      } else if (predictedClass === 1) {
        // Classe 1: Verde (borda)
        b = 1.0;
      }

      // Aplicar as cores no array de cores
      colors[index * 3] = r; // R
      colors[index * 3 + 1] = g; // G
      colors[index * 3 + 2] = b; // B
    });

    // Atualizar as cores do mesh
    mesh.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
    mesh.geometry.attributes.color.needsUpdate = true;

    // Definir o material do mesh para usar as cores dos vértices
    mesh.material = new THREE.MeshBasicMaterial({
      vertexColors: true,
      transparent: true,
      opacity: 0.5,
      side: THREE.DoubleSide,
    });
  });
}

async function testModelMesh(models, inputTensor, mesh) {
  // Obter o array de posições do mesh
  const positions = mesh.geometry.attributes.position.array;
  const colors = new Float32Array(positions.length); // Array para armazenar as cores

  // Garantir que o TensorFlow.js está pronto
  if (!tf.getBackend()) {
    tf.setBackend("webgl"); // ou "cpu"
    await tf.ready();
  }

  try {
    // Fazer predições com todos os modelos e aguardar os resultados
    const allPredictions = await Promise.all(
      models.map((model) => model.predict(inputTensor).array())
    );

    // Para cada vértice, determinar a classe com maior "voto"
    for (let index = 0; index < allPredictions[0].length; index++) {
      const votes = [0, 0]; // Contadores para classes 0 e 1

      // Contar votos de cada modelo para o vértice atual
      allPredictions.forEach((modelPreds) => {
        const pred = modelPreds[index];
        const predictedClass = pred.indexOf(Math.max(...pred));
        votes[predictedClass]++;
      });

      // Verificar votos e determinar classe
      let majorityClass;
      if (votes[0] === 2 && votes[1] === 2) {
        majorityClass = -1; // Indicação de indecisão
      } else {
        majorityClass = votes[0] > votes[1] ? 0 : 1;
      }

      // Colorir o vértice com base na classe
      let r = 0,
        g = 0,
        b = 0;
      if (majorityClass === 0) {
        g = 1.0; // Classe 0: Azul (dente)
      } else if (majorityClass === 1) {
        b = 1.0; // Classe 1: Verde (borda)
      } else if (majorityClass === -1) {
        r = 1.0; // Indecisão: Vermelho
      }

      // Aplicar as cores no array de cores
      colors[index * 3] = r; // R
      colors[index * 3 + 1] = g; // G
      colors[index * 3 + 2] = b; // B
    }

    // Atualizar as cores do mesh
    mesh.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
    mesh.geometry.attributes.color.needsUpdate = true;

    // Definir o material do mesh para usar as cores dos vértices
    mesh.material = new THREE.MeshBasicMaterial({
      vertexColors: true,
      transparent: true,
      opacity: 0.5,
      side: THREE.DoubleSide,
    });
  } catch (error) {
    console.error("Erro durante a predição:", error);
  }
}

function calculateClassWeights(labelTensor) {
  const labels = labelTensor.arraySync();
  let countClass0 = 0;
  let countClass1 = 0;

  // Contar a quantidade de exemplos de cada classe
  labels.forEach((label) => {
    if (label[0] === 1) countClass0++;
    if (label[1] === 1) countClass1++;
  });

  console.log(`Class 0: ${countClass0}, Class 1: ${countClass1}`);

  // Calcular o peso inversamente proporcional à quantidade de exemplos
  const weightClass0 = countClass1 / countClass0; // Classe minoritária terá peso maior
  const weightClass1 = countClass0 / countClass1; // Classe majoritária terá peso menor

  return {
    0: weightClass0,
    1: weightClass1,
  };
}

function checkClassDistribution(labelTensor) {
  const labels = labelTensor.arraySync();
  let countClass0 = 0;
  let countClass1 = 0;

  labels.forEach((label) => {
    if (label[0] === 1) countClass0++;
    if (label[1] === 1) countClass1++;
  });

  console.log(`Class 0: ${countClass0}, Class 1: ${countClass1}`);
}

export {
  tf,
  prepareData,
  createModel,
  trainModel,
  evaluateModel,
  testModel,
  testModelMesh,
  checkClassDistribution,
  splitData,
};
