import * as THREE from "three";

function getNeighbors(vertex, adjacencyMap) {
  // Retorna os vizinhos do vértice a partir do mapa de adjacência
  return adjacencyMap.get(vertex) || [];
}

function calculateCurvature(key, vertex, adjacencyMap) {
  // Exemplo simples de curvatura baseada na diferença de posição com os vizinhos
  const neighbors = getNeighbors(key, adjacencyMap);
  let sumDistances = 0;

  neighbors.forEach((neighbor) => {
    sumDistances += vertex.distanceTo(neighbor);
  });

  const curvature = sumDistances / neighbors.length; // Exemplo básico
  return curvature;
}

function getAdjacencyMap(mesh) {
  const adjacencyMap = new Map();
  const positions = mesh.geometry.attributes.position;

  // Percorre cada triângulo do mesh
  for (let i = 0; i < positions.count; i += 3) {
    // Coletar os três vértices do triângulo atual
    const v1 = new THREE.Vector3(
      positions.getX(i),
      positions.getY(i),
      positions.getZ(i)
    );
    const v2 = new THREE.Vector3(
      positions.getX(i + 1),
      positions.getY(i + 1),
      positions.getZ(i + 1)
    );
    const v3 = new THREE.Vector3(
      positions.getX(i + 2),
      positions.getY(i + 2),
      positions.getZ(i + 2)
    );

    // Adiciona a adjacência de cada vértice
    addAdjacency(adjacencyMap, v1, v2);
    addAdjacency(adjacencyMap, v1, v3);
    addAdjacency(adjacencyMap, v2, v3);
  }

  return adjacencyMap;
}

// Função auxiliar para adicionar adjacência
function addAdjacency(adjacencyMap, vertexA, vertexB) {
  const keyA = `${vertexA.x},${vertexA.y},${vertexA.z}`;
  const keyB = `${vertexB.x},${vertexB.y},${vertexB.z}`;

  // Se o vértice A ainda não está no mapa, inicializa
  if (!adjacencyMap.has(keyA)) {
    adjacencyMap.set(keyA, []);
  }
  // Se o vértice B ainda não está no mapa, inicializa
  if (!adjacencyMap.has(keyB)) {
    adjacencyMap.set(keyB, []);
  }

  // Adiciona o vértice B à lista de adjacentes de A, e vice-versa
  adjacencyMap.get(keyA).push(vertexB);
  adjacencyMap.get(keyB).push(vertexA);
}

function applyColorsToMeshUsingTargets(mesh, targetVertices) {
  // Criar uma cópia do mesh original
  const meshCopy = mesh.clone();
  meshCopy.geometry = mesh.geometry.clone(); // Clonar a geometria para evitar modificar a original

  const positions = meshCopy.geometry.attributes.position.array;
  const numVertices = positions.length / 3;

  // Criar um array para armazenar as cores de cada vértice
  const colors = new Float32Array(numVertices * 3);

  // Iterar sobre cada vértice e aplicar a cor com base no targetVertices
  for (let i = 0; i < numVertices; i++) {
    const x = positions[i * 3];
    const y = positions[i * 3 + 1];
    const z = positions[i * 3 + 2];

    const key = `${x},${y},${z}`;
    const isTarget = targetVertices.has(key);

    let color;
    if (isTarget) {
      color = new THREE.Color(0x44ff44); // Verde (Borda)
    } else {
      color = new THREE.Color(0x4444ff); // Azul (Dente)
    }

    // Atribuir a cor ao buffer de cores
    colors[i * 3] = color.r;
    colors[i * 3 + 1] = color.g;
    colors[i * 3 + 2] = color.b;
  }

  // Criar um novo atributo de cor e adicioná-lo à geometria da cópia do mesh
  meshCopy.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

  // Definir o material para utilizar as cores de vértice
  meshCopy.material = new THREE.MeshBasicMaterial({
    vertexColors: true,
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide,
  });

  return meshCopy; // Retorna a cópia com as cores aplicadas
}


function applyColorsToMesh(mesh, classificationMap) {
  const positions = mesh.geometry.attributes.position.array;
  const numVertices = positions.length / 3;

  // Criar um array para armazenar as cores de cada vértice
  const colors = new Float32Array(numVertices * 3);

  // Iterar sobre cada vértice e aplicar a cor de acordo com a classificação
  for (let i = 0; i < numVertices; i++) {
    const x = positions[i * 3];
    const y = positions[i * 3 + 1];
    const z = positions[i * 3 + 2];

    const key = `${x},${y},${z}`;
    const classification = classificationMap.get(key);

    let color;
    switch (classification) {
      case 0: // Dente
        color = new THREE.Color(0x4444ff); // Azul
        break;
      case 1: // Borda
        color = new THREE.Color(0x44ff44); // Amarelo
        break;
      case 2: // Gengiva
        color = new THREE.Color(0xff4444); // Vermelhor
        break;
      default:
        color = new THREE.Color(0x000000); // Cor padrão (branco)
    }

    // Atribuir a cor ao buffer de cores
    colors[i * 3] = color.r;
    colors[i * 3 + 1] = color.g;
    colors[i * 3 + 2] = color.b;
  }

  // Criar um novo atributo de cor e adicioná-lo à geometria do mesh
  mesh.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

  // Atualizar o material para utilizar as cores de vértice
  mesh.material = new THREE.MeshBasicMaterial({
    vertexColors: true,
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide,
  });
}

function normalizeMapValues(map) {
  let min = Infinity;
  let max = -Infinity;

  // Encontrar o mínimo e o máximo no mapa
  map.forEach((value) => {
    if (value < min) min = value;
    if (value > max) max = value;
  });

  // Normalizar os valores no mapa
  map.forEach((value, key) => {
    const normalizedValue = (value - min) / (max - min);
    map.set(key, normalizedValue);
  });

  return map;
}

// eslint-disable-next-line no-unused-vars
function applyHeightGradientToMesh(mesh, heightMap) {
  const positions = mesh.geometry.attributes.position.array;
  const numVertices = positions.length / 3;

  // Criar um array para armazenar as cores de cada vértice
  const colors = new Float32Array(numVertices * 3);

  // Iterar sobre cada vértice e aplicar a cor de acordo com o heightMap
  for (let i = 0; i < numVertices; i++) {
    const x = positions[i * 3];
    const y = positions[i * 3 + 1];
    const z = positions[i * 3 + 2];

    const key = `${x},${y},${z}`;
    const heightValue = heightMap.get(key);

    // Definir o gradiente de cores (azul na base, vermelho no topo)
    const color = new THREE.Color();
    color.setRGB(1 - heightValue, heightValue, 0); // Transição de azul (0) para vermelho (1)

    // Atribuir a cor ao buffer de cores
    colors[i * 3] = color.r;
    colors[i * 3 + 1] = color.g;
    colors[i * 3 + 2] = color.b;
  }

  // Criar um novo atributo de cor e adicioná-lo à geometria do mesh
  mesh.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

  // Atualizar o material para utilizar as cores de vértice
  mesh.material = new THREE.MeshBasicMaterial({
    vertexColors: true,
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide,
  });
}

function createScenario(mesh, targetVertices) {
  // Estrutura de dados para armazenar o mapa do mesh
  const scenarioMap = {
    vertices: [], // Lista de vértices com suas propriedades
    connections: [], // Lista de conexões entre vértices (adjacências)
    curvatureMap: new Map(), // Curvatura de cada vértice
    heightMap: new Map(), // Altura Y de cada vértice
    gradientMap: new Map(), // Gradiente de curvatura de cada vértice
    vertexDensityMap: new Map(), // Densidade de vértices ao redor de cada vértice
    classificationMap: new Map(), // Classificação fake de cada vértice (gengiva, borda, dentes)
    distanceMap: new Map(), // Distância normalizada de cada vértice em relação ao centro do modelo
    targets: targetVertices, // Lista de vértices que são os targets
    maxillary: mesh.userData.modelType === "maxillary" ? 1 : 0,
    minX: Infinity,
    maxX: -Infinity,
    minY: Infinity,
    maxY: -Infinity,
    minZ: Infinity,
    maxZ: -Infinity,
  };

  const grid = new Map();
  const gridSize = 0.5; // Tamanho da célula da grid, baseado no raio de busca
  const positions = mesh.geometry.attributes.position.array;
  const numVertices = positions.length / 3;

  function getGridKey(vertex) {
    const x = Math.floor(vertex.x / gridSize);
    const y = Math.floor(vertex.y / gridSize);
    const z = Math.floor(vertex.z / gridSize);
    return `${x},${y},${z}`;
  }
  // 1. Calcular o centro do modelo e a distância máxima
  const boundingBox = new THREE.Box3().setFromObject(mesh);
  const center = new THREE.Vector3();
  boundingBox.getCenter(center);

  let maxDistance = 0;

  // 2. Organizar os vértices na grid espacial e calcular distâncias ao centro
  for (let i = 0; i < numVertices; i++) {
    const x = positions[i * 3];
    const y = positions[i * 3 + 1];
    const z = positions[i * 3 + 2];

    const vertex = new THREE.Vector3(x, y, z);
    scenarioMap.vertices.push(vertex);

    // Atualizar min e max para X, Y e Z
    scenarioMap.minX = Math.min(scenarioMap.minX, x);
    scenarioMap.maxX = Math.max(scenarioMap.maxX, x);
    scenarioMap.minY = Math.min(scenarioMap.minY, y);
    scenarioMap.maxY = Math.max(scenarioMap.maxY, y);
    scenarioMap.minZ = Math.min(scenarioMap.minZ, z);
    scenarioMap.maxZ = Math.max(scenarioMap.maxZ, z);

    // Calcular a distância do vértice ao centro do modelo
    const distance = vertex.distanceTo(center);
    scenarioMap.distanceMap.set(
      `${vertex.x},${vertex.y},${vertex.z}`,
      distance
    );
    maxDistance = Math.max(maxDistance, distance);

    const key = `${vertex.x},${vertex.y},${vertex.z}`;

    const gridKey = getGridKey(vertex);
    if (!grid.has(gridKey)) {
      grid.set(gridKey, []);
    }
    grid.get(gridKey).push(vertex);

    // Classificar o vértice como gengiva, borda ou dente com base na altura
    if (targetVertices) {
      if (targetVertices.has(key)) {
        scenarioMap.classificationMap.set(key, 1); // Borda
      } else {
        scenarioMap.classificationMap.set(key, 0); // Não Borda
      }
    }
  }

  // 3. Normalizar as distâncias no distanceMap
  scenarioMap.distanceMap.forEach((distance, key) => {
    scenarioMap.distanceMap.set(key, distance / maxDistance);
  });

  // 4. Encontrar as conexões (vizinhos) para cada vértice
  const adjacencyMap = getAdjacencyMap(mesh);
  scenarioMap.connections = adjacencyMap;

  // 5. Calcular curvatura e densidade de vértices em um raio de 0.5
  scenarioMap.vertices.forEach((vertex) => {
    const key = `${vertex.x},${vertex.y},${vertex.z}`;
    const curvature = calculateCurvature(key, vertex, adjacencyMap);
    scenarioMap.curvatureMap.set(key, curvature);

    let vertexCount = 0;
    const gridKey = getGridKey(vertex);
    const neighbors = [];

    const cellOffsets = [-1, 0, 1];
    cellOffsets.forEach((xOffset) => {
      cellOffsets.forEach((yOffset) => {
        cellOffsets.forEach((zOffset) => {
          const neighborKey = `${parseInt(gridKey.split(",")[0]) + xOffset},${
            parseInt(gridKey.split(",")[1]) + yOffset
          },${parseInt(gridKey.split(",")[2]) + zOffset}`;
          if (grid.has(neighborKey)) {
            neighbors.push(...grid.get(neighborKey));
          }
        });
      });
    });

    neighbors.forEach((otherVertex) => {
      if (vertex.distanceTo(otherVertex) <= 0.5 && vertex !== otherVertex) {
        vertexCount++;
      }
    });

    scenarioMap.vertexDensityMap.set(key, vertexCount);
  });

  // Normalizar os mapas de curvatura e densidade
  scenarioMap.curvatureMap = normalizeMapValues(scenarioMap.curvatureMap);
  scenarioMap.vertexDensityMap = normalizeMapValues(
    scenarioMap.vertexDensityMap
  );

  // 6. Calcular o gradiente de curvatura
  scenarioMap.vertices.forEach((vertex) => {
    const key = `${vertex.x},${vertex.y},${vertex.z}`;
    const neighbors = adjacencyMap.get(key);
    const vertexCurvature = scenarioMap.curvatureMap.get(key);
    let gradientSum = 0;

    neighbors.forEach((neighbor) => {
      const keyNeighbor = `${neighbor.x},${neighbor.y},${neighbor.z}`;
      const neighborCurvature = scenarioMap.curvatureMap.get(keyNeighbor);
      gradientSum += Math.abs(vertexCurvature - neighborCurvature);
    });

    const gradient = gradientSum / neighbors.length;
    scenarioMap.gradientMap.set(key, gradient);
  });

  // Normalizar os mapas de gradiente e altura
  scenarioMap.gradientMap = normalizeMapValues(scenarioMap.gradientMap);
  scenarioMap.heightMap = normalizeMapValues(scenarioMap.heightMap);

  applyColorsToMesh(mesh, scenarioMap.classificationMap);
  //applyHeightGradientToMesh(mesh, scenarioMap.distanceMap)

  return scenarioMap;
}

function createSimpleScenario(mesh, targetVertices) {
  // Estrutura de dados para armazenar o mapa do mesh
  const scenarioMap = {
    classificationMap: new Map(), // Classificação fake de cada vértice (gengiva, borda, dentes)
    targets: targetVertices, // Lista de vértices que são os targets
  };

  const grid = new Map();
  const gridSize = 0.5; // Tamanho da célula da grid, baseado no raio de busca
  const positions = mesh.geometry.attributes.position.array;
  const numVertices = positions.length / 3;

  function getGridKey(vertex) {
    const x = Math.floor(vertex.x / gridSize);
    const y = Math.floor(vertex.y / gridSize);
    const z = Math.floor(vertex.z / gridSize);
    return `${x},${y},${z}`;
  }
  // 1. Calcular o centro do modelo e a distância máxima
  const boundingBox = new THREE.Box3().setFromObject(mesh);
  const center = new THREE.Vector3();
  boundingBox.getCenter(center);

  // 2. Organizar os vértices na grid espacial e calcular distâncias ao centro
  for (let i = 0; i < numVertices; i++) {
    const x = positions[i * 3];
    const y = positions[i * 3 + 1];
    const z = positions[i * 3 + 2];

    const vertex = new THREE.Vector3(x, y, z);

    const key = `${vertex.x},${vertex.y},${vertex.z}`;

    const gridKey = getGridKey(vertex);
    if (!grid.has(gridKey)) {
      grid.set(gridKey, []);
    }
    grid.get(gridKey).push(vertex);

    // Classificar o vértice como gengiva, borda ou dente com base na altura
    if (targetVertices) {
      if (targetVertices.has(key)) {
        scenarioMap.classificationMap.set(key, 1); // Borda
      } else {
        scenarioMap.classificationMap.set(key, 0); // Não Borda
      }
    }
  }
  applyColorsToMesh(mesh, scenarioMap.classificationMap);

  return scenarioMap;
}

function getRandomVertices(mesh, numTargets) {
  const vertices = mesh.geometry.attributes.position.array; // Pega os vértices do mesh
  const numVertices = vertices.length / 3;

  // Garantir que não tentamos pegar mais vértices do que os disponíveis
  if (numTargets > numVertices) {
    console.warn(
      "Número de alvos é maior que o número de vértices disponíveis. Ajustando para o número máximo de vértices."
    );
    numTargets = numVertices;
  }

  const randomTargets = [];

  // Selecionar vértices aleatórios
  for (let i = 0; i < numTargets; i++) {
    const randomIndex = Math.floor(Math.random() * numVertices); // Índice aleatório

    // Pega o vértice aleatório e cria um THREE.Vector3 para ele
    const vertex = new THREE.Vector3(
      vertices[randomIndex * 3],
      vertices[randomIndex * 3 + 1],
      vertices[randomIndex * 3 + 2]
    );

    randomTargets.push(vertex);
  }

  return randomTargets;
}

function getDenseVertices(scenarioMap, numTargets, minDistance = 2) {
  const vertexDensityMap = scenarioMap.vertexDensityMap;
  const vertices = scenarioMap.vertices;

  // Garantir que não tentamos pegar mais vértices do que os disponíveis
  if (numTargets > vertices.length) {
    console.warn(
      "Número de alvos é maior que o número de vértices disponíveis. Ajustando para o número máximo de vértices."
    );
    numTargets = vertices.length;
  }

  // Converter o vertexDensityMap para uma array de pares [chave, densidade]
  const densityArray = Array.from(vertexDensityMap.entries());

  // Ordenar pela densidade em ordem decrescente (maior número de vizinhos primeiro)
  densityArray.sort((a, b) => b[1] - a[1]);

  const denseTargets = [];

  // Função para verificar a distância mínima entre um novo vértice e os já escolhidos
  function isFarEnough(newVertex, targets, minDist) {
    return targets.every((target) => newVertex.distanceTo(target) >= minDist);
  }

  // Selecionar os vértices com as maiores densidades, garantindo a distância mínima
  for (
    let i = 0;
    i < densityArray.length && denseTargets.length < numTargets;
    i++
  ) {
    const key = densityArray[i][0]; // A chave do vértice
    const [x, y, z] = key.split(",").map(Number); // Extrair as coordenadas do vértice a partir da chave
    const vertex = new THREE.Vector3(x, y, z);

    // Verificar se o vértice está longe o suficiente dos outros já selecionados
    if (isFarEnough(vertex, denseTargets, minDistance)) {
      denseTargets.push(vertex);
    }
  }

  return denseTargets;
}

function addSpheresToMesh(vertexMap, radius = 0.1, color = 0xff0000) {
  // Criar um geometry buffer para o mesh
  const sphereGeometry = new THREE.SphereGeometry(radius, 16, 16);
  const material = new THREE.MeshBasicMaterial({ color });

  // Mesh de grupo para agrupar todas as esferas
  const groupMesh = new THREE.Group();

  // Adicionar esferas para cada vértice no mapa
  vertexMap.forEach(({ vertex }) => {
    const sphere = new THREE.Mesh(sphereGeometry, material);

    // Posicionar a esfera no vértice correspondente
    sphere.position.set(vertex.x, vertex.y, vertex.z);

    // Adicionar a esfera ao grupo
    groupMesh.add(sphere);
  });

  return groupMesh;
}

function getArcoDentarioVertices(
  scenarioMap,
  minHeight,
  maxHeight,
  minDensity
) {
  const arcoDentarioMap = new Map(); // Armazenará os vértices que estão na região do arco dentário

  scenarioMap.vertices.forEach((vertex) => {
    const key = `${vertex.x},${vertex.y},${vertex.z}`;
    const height = scenarioMap.heightMap.get(key);
    const density = scenarioMap.vertexDensityMap.get(key);

    // Verifica se o vértice está dentro da faixa de altura e se possui densidade suficiente
    if (height >= minHeight && height <= maxHeight && density >= minDensity) {
      arcoDentarioMap.set(key, { vertex, height, density });
    }
  });

  return arcoDentarioMap;
}

function removeOutliers(
  scenarioMap,
  arcoDentarioMap,
  someHighDensityThreshold = 50
) {
  const outlierVertices = new Map();

  scenarioMap.vertices.forEach((vertex) => {
    const key = `${vertex.x},${vertex.y},${vertex.z}`;

    // Se o vértice não estiver na região do arco dentário e tiver alta densidade, é considerado outlier
    if (
      !arcoDentarioMap.has(key) &&
      scenarioMap.vertexDensityMap.get(key) > someHighDensityThreshold
    ) {
      outlierVertices.set(key, vertex);
    }
  });

  return outlierVertices;
}

export {
  getDenseVertices,
  getRandomVertices,
  createScenario,
  createSimpleScenario,
  addSpheresToMesh,
  getArcoDentarioVertices,
  removeOutliers,
  applyColorsToMeshUsingTargets
};
