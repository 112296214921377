import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import store from "./store";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { md1 } from "vuetify/blueprints";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

import "./assets/global.css";

import { messages_en } from "./locales/en/messages_en";
import { messages_it } from "./locales/it/messages_it";
import { messages_pt_BR } from "./locales/pt-BR/messages_pt-BR";

const messages = {
  en : messages_en,
  it : messages_it,
  "pt-BR" : messages_pt_BR
};

const OrthoLightTheme = {
  dark: false,
  // colors: {
  //   background: "#FFFFFF",
  //   surface: "#FFFFFF",
  //   "surface-bright": "#f8f8ff",
  //   "surface-light": "#EEEEEE",
  //   "surface-variant": "#424242",
  //   "on-surface-variant": "#EEEEEE",
  //   primary: "#1867C0",
  //   "primary-darken-1": "#1F5592",
  //   secondary: "#48A9A6",
  //   "secondary-darken-1": "#018786",
  //   highlight: "#FFA726",
  //   error: "#B00020",
  //   info: "#2196F3",
  //   success: "#4CAF50",
  //   warning: "#FB8C00",
  //   "text-light": "#FFFFFF",
  // },
  colors: {
    background: "#F8FAFC", // Fundo neutro suave (branco acinzentado)
    surface: "#FFFFFF", // Superfície principal branca
    "surface-bright": "#F4FDF9", // Verde muito claro, dando um tom de frescor
    "surface-light": "#E8F5E9", // Verde suave, para áreas secundárias
    "surface-variant": "#4A6572", // Cinza-azulado mais escuro, elegante e associado a calma
    "on-surface-variant": "#E0F2F1", // Verde claro, harmonizando com "surface-variant"
    primary: "#4CAF50", // Verde brilhante, associado a saúde e sucesso
    "primary-darken-1": "#388E3C", // Verde mais escuro, para contraste
    secondary: "#81D4FA", // Azul claro, sensação de confiança e tranquilidade
    "secondary-darken-1": "#0288D1", // Azul médio, profissional e confiável
    highlight: "#FFC107", // Amarelo suave, para chamar atenção sem agressividade
    error: "#D32F2F", // Vermelho mais moderado, ainda sinalizando erro
    info: "#29B6F6", // Azul claro, para informações neutras
    success: "#66BB6A", // Verde médio, para feedback positivo
    warning: "#FFA726", // Laranja claro, para alertas sutis
    "text-light": "#ffffff", // Texto claro para superfícies escuras,
    "text-dark": "#000000", // Texto claro para superfícies escuras
},
  variables: {
    "border-color": "#000000",
    "border-opacity": 0.12,
    "high-emphasis-opacity": 0.87,
    "medium-emphasis-opacity": 0.6,
    "disabled-opacity": 0.38,
    "idle-opacity": 0.04,
    "hover-opacity": 0.04,
    "focus-opacity": 0.12,
    "selected-opacity": 0.08,
    "activated-opacity": 0.12,
    "pressed-opacity": 0.12,
    "dragged-opacity": 0.08,
    "theme-kbd": "#212529",
    "theme-on-kbd": "#FFFFFF",
    "theme-code": "#F5F5F5",
    "theme-on-code": "#000000",
  },
};

const i18n = createI18n({
  locale: localStorage.getItem("currentLanguage") || "pt-BR",
  fallbackLocale: "en",
  messages,
});

const vuetify = createVuetify({
  components,
  directives,
  md1,
  theme: {
    defaultTheme: "OrthoLightTheme",
    themes: {
      OrthoLightTheme,
    },
  },
});

const app = createApp(App);

app.use(router);
app.use(store);
app.use(i18n);
app.use(vuetify);
app.mount("#app");
