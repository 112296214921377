import * as THREE from "three";

class ModelOrientation {
  constructor(model, scene) {
    this.model = model;
    this.scene = scene;
    this.boundingBox = new THREE.Box3().setFromObject(model);
    this.frontDirection = null;
    this.upDirection = null;
    this.leftDirection = null;
    this.cornerPosition = null;
    this.identifyOrientation();
  }

  identifyOrientation() {
    const boxSize = new THREE.Vector3();
    this.boundingBox.getSize(boxSize);

    const axes = ['x', 'y', 'z'];
    const sortedAxes = axes.sort((a, b) => boxSize[b] - boxSize[a]);
    this.sideAxis = sortedAxes[0];
    this.frontAxis = sortedAxes[1];
    this.heightAxis = sortedAxes[2];

    this.findDenseRegions();
  }

  findDenseRegions() {
    const voxelDensity = { left: 0, right: 0, up: 0, down: 0, front: 0, back: 0 };
    const positionAttribute = this.model.geometry.attributes.position;

    const frontMidpoint = (this.boundingBox.max[this.frontAxis] + this.boundingBox.min[this.frontAxis]) / 2;
    const sideMidpoint = (this.boundingBox.max[this.sideAxis] + this.boundingBox.min[this.sideAxis]) / 2;
    const heightMidpoint = (this.boundingBox.max[this.heightAxis] + this.boundingBox.min[this.heightAxis]) / 2;

    for (let i = 0; i < positionAttribute.count; i++) {
      const position = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
      position.applyMatrix4(this.model.matrixWorld);

      if (position[this.frontAxis] > frontMidpoint) voxelDensity.front++;
      else voxelDensity.back++;

      if (position[this.sideAxis] < sideMidpoint) voxelDensity.left++;
      else voxelDensity.right++;

      if (position[this.heightAxis] < heightMidpoint) voxelDensity.down++;
      else voxelDensity.up++;
    }

    this.frontDirection = voxelDensity.front > voxelDensity.back ? 'front' : 'back';
    this.leftDirection = voxelDensity.left > voxelDensity.right ? 'left' : 'right';
    this.upDirection = voxelDensity.up > voxelDensity.down ? 'up' : 'down';

    this.cornerPosition = new THREE.Vector3(
      this.boundingBox[this.leftDirection === 'left' ? 'min' : 'max'][this.sideAxis],
      this.boundingBox[this.upDirection === 'down' ? 'min' : 'max'][this.heightAxis],
      this.boundingBox[this.frontDirection === 'back' ? 'min' : 'max'][this.frontAxis]
    );
  }

  getAlignmentData() {
    return {
      cornerPosition: this.cornerPosition,
      frontDirection: this.frontDirection,
      upDirection: this.upDirection,
      leftDirection: this.leftDirection,
      frontAxis: this.frontAxis,
      upAxis: this.heightAxis,
      leftAxis: this.sideAxis
    };
  }
}

class ModelAlignment {
  constructor(model, alignmentData) {
    this.model = model;
    this.cornerPosition = alignmentData.cornerPosition;
    this.frontAxis = alignmentData.frontAxis;
    this.upAxis = alignmentData.upAxis;
    this.leftAxis = alignmentData.leftAxis;
    this.alignToOrigin(alignmentData);
  }

  alignToOrigin(alignmentData) {
    // Step 1: Translate the model so the corner is at (0, 0, 0)
    const translationVector = this.cornerPosition.clone().negate();
    this.model.position.add(translationVector);

    // Step 2: Rotate the model to align the axes
    // Define target directions for each axis based on the alignment data
    const targetFront = new THREE.Vector3();
    targetFront[alignmentData.frontAxis] = alignmentData.frontDirection === 'front' ? -1 : 1;

    const targetUp = new THREE.Vector3();
    targetUp[alignmentData.upAxis] = alignmentData.upDirection === 'up' ? 1 : -1;

    const targetLeft = new THREE.Vector3();
    targetLeft[alignmentData.leftAxis] = alignmentData.leftDirection === 'left' ? -1 : 1;

    // Use the current orientation of the model's axes to calculate the necessary rotation
    const currentFront = new THREE.Vector3(0, 0, 1).applyMatrix4(this.model.matrixWorld).normalize();
    const currentUp = new THREE.Vector3(0, 1, 0).applyMatrix4(this.model.matrixWorld).normalize();
    const currentLeft = new THREE.Vector3(1, 0, 0).applyMatrix4(this.model.matrixWorld).normalize();

    // Compute rotation quaternions for each axis alignment
    const quaternionFront = new THREE.Quaternion().setFromUnitVectors(currentFront, targetFront);
    this.model.applyQuaternion(quaternionFront);

    // Recalculate the up and left directions after aligning the front
    currentUp.applyQuaternion(quaternionFront);
    const quaternionUp = new THREE.Quaternion().setFromUnitVectors(currentUp, targetUp);
    this.model.applyQuaternion(quaternionUp);

    // Finally, align the left direction
    currentLeft.applyQuaternion(quaternionUp);
    const quaternionLeft = new THREE.Quaternion().setFromUnitVectors(currentLeft, targetLeft);
    this.model.applyQuaternion(quaternionLeft);

    this.model.updateMatrixWorld(); // Apply the transformations to the model
  }
}



// Classe VoxelGrid
class VoxelGrid {
  constructor(teeth, voxelSize) {
    this.voxelSize = voxelSize;
    this.voxelGrid = new Map();
    this.initialize(teeth);
  }

  initialize(teeth) {
    teeth.forEach((tooth) => {
      const positions = tooth.geometry.attributes.position;
      for (let i = 0; i < positions.count; i += 3) {
        const p1 = new THREE.Vector3().fromBufferAttribute(positions, i);
        const p2 = new THREE.Vector3().fromBufferAttribute(positions, i + 1);
        const p3 = new THREE.Vector3().fromBufferAttribute(positions, i + 2);

        p1.applyMatrix4(tooth.matrixWorld);
        p2.applyMatrix4(tooth.matrixWorld);
        p3.applyMatrix4(tooth.matrixWorld);

        const centroid = new THREE.Vector3(
          (p1.x + p2.x + p3.x) / 3,
          (p1.y + p2.y + p3.y) / 3,
          (p1.z + p2.z + p3.z) / 3
        );

        this.insert(centroid, tooth.userData.toothNumber, p1, p2, p3);
      }
    });
  }

  insert(centroid, toothNumber, ...vertices) {
    const index = this.computeVoxelIndex(centroid);
    if (!this.voxelGrid.has(index)) {
      this.voxelGrid.set(index, {
        toothNumbers: new Set(),
        isMixed: false,
        vertices: [],
      });
    }
    const voxel = this.voxelGrid.get(index);
    if (
      !voxel.isMixed &&
      voxel.toothNumbers.size > 0 &&
      !voxel.toothNumbers.has(toothNumber)
    ) {
      voxel.isMixed = true;
    }
    voxel.toothNumbers.add(toothNumber);
    vertices.forEach((vertex) => {
      voxel.vertices.push({ position: vertex.clone(), toothNumber });
    });
  }

  computeVoxelIndex(position) {
    const x = Math.floor(position.x / this.voxelSize.x);
    const y = Math.floor(position.y / this.voxelSize.y);
    const z = Math.floor(position.z / this.voxelSize.z);
    return `${x},${y},${z}`;
  }

  query(position) {
    const indicesToCheck = this.getAdjacentIndices(position);
    const mixedVoxels = [];
    indicesToCheck.forEach((index) => {
      if (this.voxelGrid.has(index)) {
        const voxel = this.voxelGrid.get(index);
        if (voxel.isMixed) {
          mixedVoxels.push(voxel);
        }
      }
    });
    return mixedVoxels;
  }

  getAdjacentIndices(position) {
    const baseIndex = this.computeVoxelIndex(position);
    const [baseX, baseY, baseZ] = baseIndex.split(",").map(Number);

    const adjacentIndices = [];
    for (let x = baseX - 1; x <= baseX + 1; x++) {
      for (let y = baseY - 1; y <= baseY + 1; y++) {
        for (let z = baseZ - 1; z <= baseZ + 1; z++) {
          adjacentIndices.push(`${x},${y},${z}`);
        }
      }
    }
    return adjacentIndices;
  }

  findNearestNeighbor(position, mixedVoxels, toothNumber) {
    let minDistance = Infinity;
    let nearestNeighbor = null;

    mixedVoxels.forEach((voxel) => {
      voxel.vertices.forEach((vertex) => {
        if (vertex.toothNumber !== toothNumber) {
          const distance = position.distanceTo(vertex.position);
          if (distance < minDistance) {
            minDistance = distance;
            nearestNeighbor = vertex;
          }
        }
      });
    });

    return { minDistance, nearestNeighbor };
  }

  findClosestVertices() {
    const mixedVoxels = Array.from(this.voxelGrid.values()).filter(
      (voxel) => voxel.isMixed === true
    );

    const closestVertices = {}; // Objeto para armazenar os vértices mais próximos

    // Iterar sobre os voxels impuros
    mixedVoxels.forEach((voxel) => {
      // Iterar sobre os vértices do voxel
      voxel.vertices.forEach((vertex) => {
        const { position, toothNumber } = vertex;
        // Verificar se já existe uma entrada para este dente no objeto
        const key = `${position.x}_${position.y}_${position.z}`;
        if (!closestVertices[key]) {
          closestVertices[key] = { position, minDistance: Infinity };
        } else {
          const currentMinDistance = closestVertices[key].minDistance;
          const { minDistance } = this.findNearestNeighbor(
            position,
            this.query(position),
            toothNumber
          );
          // Atualizar a menor distância se a distância atual for menor
          if (minDistance < currentMinDistance) {
            closestVertices[key] = { position, minDistance };
          }
        }
      });
    });

    return closestVertices;
  }

  generateVertexColorMesh(caller) {
    // Verifique se o mesh para a visualização com o mapa de distância já existe
    let distanceMesh = caller.scene.getObjectByName("distanceMesh");
    if (!distanceMesh) {
      // Se não existir, crie um novo mesh para armazenar a visualização
      distanceMesh = new THREE.Group();
      distanceMesh.name = "distanceMesh";
      distanceMesh.userData.name = "distanceMesh";
      caller.scene.add(distanceMesh);
    } else {
      // Se existir, limpe seu conteúdo (remova todos os filhos)
      distanceMesh.clear();
    }

    // Encontre todos os dentes na cena
    const teeth = caller.findTeethInScene();

    // Obtenha os vértices mais próximos
    const closestVertices = this.findClosestVertices();

    let maxDistance = 0;

    // Para cada dente na cena
    teeth.forEach((tooth) => {
      const toothGeometry = tooth.geometry;

      // Verifique se a geometria do dente é uma BufferGeometry
      if (toothGeometry instanceof THREE.BufferGeometry) {
        // Crie uma cópia da geometria do dente
        const coloredToothGeometry = toothGeometry.clone();

        // Calcule as cores para cada vértice na geometria do dente
        const colors = [];
        const positions = coloredToothGeometry.attributes.position;
        for (let i = 0; i < positions.count; i++) {
          const position = new THREE.Vector3().fromBufferAttribute(
            positions,
            i
          );
          const realPosition = position.applyMatrix4(tooth.matrixWorld); // Posição real do vértice na cena

          // Use os vértices mais próximos encontrados na função findClosestVertices
          const key = `${realPosition.x}_${realPosition.y}_${realPosition.z}`;
          let minDistance = Infinity;

          if (closestVertices[key]) {
            minDistance = closestVertices[key].minDistance;
          }

          if (maxDistance<minDistance && minDistance!==Infinity) maxDistance = minDistance;

          // Mapeie a distância mínima entre 0 e 1
          const normalizedDistance = minDistance / 2.2;

          //Interpole entre as cores vermelha e azul com base na distância normalizada
          const color = new THREE.Color(0xffff00);
          color.lerp(
            new THREE.Color(0xeeeeff),
            normalizedDistance > 1 ? 1 : normalizedDistance
          );

          colors.push(color.r, color.g, color.b); // Adicione as componentes de cor ao array de cores
        }

        // Crie um BufferAttribute para armazenar as cores e atribua-o à geometria do dente
        const colorAttribute = new THREE.BufferAttribute(
          new Float32Array(colors),
          3
        );
        coloredToothGeometry.setAttribute("color", colorAttribute);

        // Crie um material usando VertexColors e atribua-o ao novo mesh do dente
        const material = new THREE.MeshBasicMaterial({
          vertexColors: true,
          transparent: true,
          opacity: 0.5,
        });
        const coloredToothMesh = new THREE.Mesh(coloredToothGeometry, material);

        // Aplique a mesma matriz de transformação do dente original
        coloredToothMesh.position.copy(tooth.position);
        coloredToothMesh.rotation.copy(tooth.rotation);
        coloredToothMesh.scale.copy(tooth.scale);
        coloredToothMesh.updateMatrix();

        // Adicione o novo mesh do dente ao mesh de distância
        distanceMesh.add(coloredToothMesh);
      }
    });
    console.warn(maxDistance)
  }

  // Define a função para interpolar entre cores com base em uma paleta de cores
  interpolateColor(normalizedDistance) {
    // Defina a paleta de cores do mapa de temperatura
    const palette = [
      { color: new THREE.Color(0xff0000), position: 0.0 }, // Vermelho
      { color: new THREE.Color(0xffa500), position: 0.2 }, // Laranja
      { color: new THREE.Color(0xffff00), position: 0.4 }, // Amarelo
      { color: new THREE.Color(0x00ff00), position: 0.6 }, // Verde
      { color: new THREE.Color(0x00ffff), position: 0.8 }, // Ciano
      { color: new THREE.Color(0x0000ff), position: 1.0 }, // Azul
    ];

    // Encontre os dois pontos da paleta entre os quais estamos interpolando
    for (let i = 0; i < palette.length - 1; i++) {
      const start = palette[i];
      const end = palette[i + 1];
      if (
        normalizedDistance >= start.position &&
        normalizedDistance <= end.position
      ) {
        // Calcule a posição relativa entre os dois pontos
        const range = end.position - start.position;
        const localNormalized = (normalizedDistance - start.position) / range;
        // Interpole a cor
        return start.color.clone().lerp(end.color, localNormalized);
      }
    }
    // Caso a distância normalizada esteja fora do intervalo (deveria estar entre 0 e 1)
    return new THREE.Color(0x000000); // Preto como fallback
  }
}

export { VoxelGrid, ModelOrientation, ModelAlignment };
