import * as THREE from "three";
import Delaunator from "delaunator";
import KMeans from "ml-kmeans";
import { MeshBVH, acceleratedRaycast } from "three-mesh-bvh";

// eslint-disable-next-line no-unused-vars
import { RigidBodySimulator, SoftBody, detectSoftBodyHardBodyCollision } from '@/class/softBody'

export default {
  methods: {
    analyzeArrayBVH(geometry, spheresData, maxRadius = 0.25) {
      if (!Array.isArray(spheresData) || spheresData.length === 0) {
        console.warn("O array 'spheresData' está vazio ou inválido.");
        return new Set(); // Retorna um Set vazio se spheresData for inválido
      }
    
      // Criar a BVH para o mesh A
      const bvh = new MeshBVH(geometry);
    
      // Set para armazenar vértices únicos que estão dentro das esferas
      const verticesInsideSpheres = new Set();
    
      // Iterar sobre as esferas no array
      spheresData.forEach((sphereData) => {
        const { position } = sphereData;
    
        if (!position || typeof position.x !== 'number' || typeof position.y !== 'number' || typeof position.z !== 'number') {
          console.warn("Posição inválida encontrada em uma esfera:", sphereData);
          return;
        }
    
        const spherePosition = new THREE.Vector3(position.x, position.y, position.z);
        const sphereRadius = maxRadius; // Usando o valor de maxRadius como raio padrão
    
        // Usar a BVH para procurar por interseções
        const boundingSphere = new THREE.Sphere(spherePosition, sphereRadius);
    
        // Função para testar se o vértice está dentro da esfera
        bvh.shapecast({
          intersectsBounds: (box) => {
            return boundingSphere.intersectsBox(box);
          },
          intersectsTriangle: (tri) => {
            const vertices = [tri.a, tri.b, tri.c]; // Usar diretamente os vértices do triângulo
          
            for (const vertex of vertices) {
              const key = `${vertex.x},${vertex.y},${vertex.z}`;
              if (boundingSphere.containsPoint(vertex)) {
                verticesInsideSpheres.add(key); // Adiciona vértice diretamente
              }
            }
          
            return false;
          }
        });
      });
    
      return verticesInsideSpheres; // Retorna o Set de vértices únicos
    },    
    analyzeSpheresBVH(geometry, maxRadius = 0.25) {
      const borderInfo = this.scene.getObjectByName("borderInfo");
      if (!borderInfo) {
        console.warn("Mesh 'borderInfo' não encontrado.");
        return new Set(); // Retorna um Set vazio se o borderInfo não for encontrado
      }
    
      // Criar a BVH para o mesh A
      const bvh = new MeshBVH(geometry);
    
      // Set para armazenar vértices únicos que estão dentro das esferas
      const verticesInsideSpheres = new Set();
    
      // Iterar sobre as esferas no borderInfo
      borderInfo.children.forEach((sphere) => {
        const spherePosition = sphere.position;
        const sphereRadius = maxRadius; // Assumindo que o scale da esfera define o raio
    
        // Usar a BVH para procurar por interseções
        const boundingSphere = new THREE.Sphere(spherePosition, sphereRadius);
    
        // Função para testar se o vértice está dentro da esfera
        bvh.shapecast({
          intersectsBounds: (box) => {
            return boundingSphere.intersectsBox(box);
          },
          intersectsTriangle: (tri) => {
            // Verificar se algum vértice do triângulo está dentro da esfera
            for (let i = 0; i < 3; i++) {
              const vertex = tri.a.clone().lerp(tri.b, i / 3).lerp(tri.c, (i + 1) / 3);
              const key = `${vertex.x},${vertex.y},${vertex.z}`;
              if (boundingSphere.containsPoint(vertex)) {
                verticesInsideSpheres.add(key); // Adiciona o vértice ao Set usando a chave
              }
            }
            return false;
          }
        });
      });
    
      return verticesInsideSpheres; // Retorna o Set de vértices únicos
    },
    cloneMesh(mesh) {
      const clonedMesh = mesh.clone(); // Clone superficial do mesh

      // Fazer uma cópia profunda da geometria
      clonedMesh.geometry = mesh.geometry.clone();

      // Fazer uma cópia profunda do material
      clonedMesh.material = mesh.material.clone();

      return clonedMesh;
    },
    getCurvatureStatistics(curvatureMap) {
      let sum = 0;
      let count = 0;
      let sumOfSquares = 0;

      // Calcular a soma e o número de vértices
      curvatureMap.forEach((curvature) => {
        sum += curvature;
        sumOfSquares += curvature * curvature;
        count++;
      });

      const mean = sum / count;
      const variance = sumOfSquares / count - mean * mean;
      const stddev = Math.sqrt(variance);

      console.log(`Curvature Statistics: mean = ${mean}, stddev = ${stddev}`);

      return { mean, stddev };
    },
    getYRangeWithHighestCurvature(curvatureMap) {
      const yCurvaturePairs = [];

      // Itera sobre o mapa de curvatura, coletando as coordenadas Y e seus respectivos valores de curvatura
      curvatureMap.forEach((curvature, key) => {
        const vertex = new THREE.Vector3(...key.split(",").map(parseFloat)); // Extrai as coordenadas X, Y, Z
        yCurvaturePairs.push({ y: vertex.y, curvature });
      });

      // Ordena os pares pela curvatura em ordem decrescente (maior curvatura primeiro)
      yCurvaturePairs.sort((a, b) => b.curvature - a.curvature);

      return yCurvaturePairs;
    },
    getCurvatureYRangeForTopCurvatures(yCurvaturePairs, mean, stddev) {
      // Filtrar apenas os vértices cuja curvatura é significativamente acima da média
      const highCurvatureVertices = yCurvaturePairs.filter(
        ({ curvature }) => curvature > mean + stddev
      );

      // Se não houver vértices suficientes, reduzir o filtro
      if (highCurvatureVertices.length === 0) {
        console.warn(
          "Nenhum vértice com curvatura acima de mean + stddev. Reduzindo critério."
        );
        return { yMinHighCurvature: null, yMaxHighCurvature: null };
      }

      // Encontrar os valores mínimo e máximo de Y nesses vértices de alta curvatura
      let yMinHighCurvature = Infinity;
      let yMaxHighCurvature = -Infinity;

      highCurvatureVertices.forEach(({ y }) => {
        if (y < yMinHighCurvature) yMinHighCurvature = y;
        if (y > yMaxHighCurvature) yMaxHighCurvature = y;
      });

      console.log(
        `Faixa de Y das maiores curvaturas: yMinHighCurvature = ${yMinHighCurvature}, yMaxHighCurvature = ${yMaxHighCurvature}`
      );

      return { yMinHighCurvature, yMaxHighCurvature };
    },
    getSigmaForYWithCurvatureFocus(
      y,
      yMin,
      yMax,
      yMinHighCurvature,
      yMaxHighCurvature,
      sigmaMin,
      sigmaMax
    ) {
      // Se Y está dentro da faixa de maior curvatura, aplica o sigma mais baixo
      if (y >= yMinHighCurvature && y <= yMaxHighCurvature) {
        return sigmaMin;
      }

      // Caso contrário, ajusta sigma normalmente com base na altura Y
      const normalizedY = (y - yMin) / (yMax - yMin);
      return sigmaMin + normalizedY * (sigmaMax - sigmaMin);
    },
    getSigmaForCurvature(curvature, mean, stddev, sigmaMin, sigmaMax) {
      // Se a curvatura é maior que a média + desvio padrão, aplica sigma baixo
      if (curvature > mean + stddev) {
        return sigmaMin; // Preservar áreas de alta curvatura
      }

      // Caso contrário, aplica sigma alto para suavizar mais as regiões de baixa curvatura
      return sigmaMax;
    },
    applyGaussianFilterWithCurvatureFocus(
      curvatureMap,
      adjacencyMap,
      mean,
      stddev,
      sigmaMin = 0.5,
      sigmaMax = 5.0
    ) {
      const filteredCurvatureMap = new Map();

      curvatureMap.forEach((curvature, key) => {
        const vertex = new THREE.Vector3(...key.split(",").map(parseFloat)); // Converte a chave para o vértice
        const neighbors = adjacencyMap.get(key);

        if (!neighbors) {
          filteredCurvatureMap.set(key, curvature); // Sem vizinhos, mantém a curvatura original
          return;
        }

        // Calcula o sigma com base na curvatura do vértice
        const sigma = this.getSigmaForCurvature(
          curvature,
          mean,
          stddev,
          sigmaMin,
          sigmaMax
        );

        let weightedSum = 0;
        let totalWeight = 0;

        // Para cada vizinho, aplica a suavização gaussiana com o sigma escolhido
        neighbors.forEach((neighbor) => {
          const neighborKey = `${neighbor.x},${neighbor.y},${neighbor.z}`;
          const neighborCurvature = curvatureMap.get(neighborKey);
          if (neighborCurvature !== undefined) {
            const distance = vertex.distanceTo(neighbor);
            const weight = this.gaussianWeight(distance, sigma);

            weightedSum += neighborCurvature * weight;
            totalWeight += weight;
          }
        });

        const smoothedCurvature = weightedSum / totalWeight;
        filteredCurvatureMap.set(key, smoothedCurvature);
      });

      return filteredCurvatureMap;
    },
    getYBounds(mesh) {
      const positions = mesh.geometry.attributes.position;
      let yMin = Infinity;
      let yMax = -Infinity;

      for (let i = 0; i < positions.count; i++) {
        const y = positions.getY(i);
        if (y < yMin) yMin = y;
        if (y > yMax) yMax = y;
      }

      return { yMin, yMax };
    },
    getSigmaForY(y, yMin, yMax, sigmaMin, sigmaMax) {
      // Normaliza a altura Y entre yMin e yMax
      const normalizedY = (y - yMin) / (yMax - yMin);

      // Interpola o valor de sigma entre sigmaMin e sigmaMax
      return sigmaMin + normalizedY * (sigmaMax - sigmaMin);
    },
    gaussianWeight(distance, sigma) {
      return Math.exp(-(distance * distance) / (2 * sigma * sigma));
    },
    applyGaussianFilterWithVariableSigma(
      curvatureMap,
      adjacencyMap,
      yMin,
      yMax,
      sigmaMin = 0.5,
      sigmaMax = 5.0
    ) {
      const filteredCurvatureMap = new Map();

      curvatureMap.forEach((curvature, key) => {
        const vertex = new THREE.Vector3(...key.split(",").map(parseFloat)); // Converte a chave para o vértice
        const neighbors = adjacencyMap.get(key);

        if (!neighbors) {
          filteredCurvatureMap.set(key, curvature); // Sem vizinhos, mantém a curvatura original
          return;
        }

        // Obtém o valor de sigma para este vértice com base em sua altura Y
        const sigma = this.getSigmaForY(
          vertex.y,
          yMin,
          yMax,
          sigmaMin,
          sigmaMax
        );

        let weightedSum = 0;
        let totalWeight = 0;

        // Calcula a nova curvatura baseada nos vizinhos
        neighbors.forEach((neighbor) => {
          const neighborKey = `${neighbor.x},${neighbor.y},${neighbor.z}`;
          const neighborCurvature = curvatureMap.get(neighborKey);
          if (neighborCurvature !== undefined) {
            const distance = vertex.distanceTo(neighbor);
            const weight = this.gaussianWeight(distance, sigma);

            weightedSum += neighborCurvature * weight;
            totalWeight += weight;
          }
        });

        // Calcula a nova curvatura suavizada
        const smoothedCurvature = weightedSum / totalWeight;
        filteredCurvatureMap.set(key, smoothedCurvature);
      });

      return filteredCurvatureMap;
    },
    cascadeApplyGaussianFilter(
      curvatureMap,
      adjacencyMap,
      iterations = 1,
      sigma = 1.0
    ) {
      let currentCurvatureMap = curvatureMap; // Começa com o mapa de curvatura original

      for (let i = 0; i < iterations; i++) {
        currentCurvatureMap = this.applyGaussianFilter(
          currentCurvatureMap,
          adjacencyMap,
          sigma
        );
      }

      return currentCurvatureMap;
    },
    applyGaussianFilter(curvatureMap, adjacencyMap, sigma = 1.0) {
      const filteredCurvatureMap = new Map();

      curvatureMap.forEach((curvature, key) => {
        const neighbors = adjacencyMap.get(key);
        if (!neighbors) {
          filteredCurvatureMap.set(key, curvature); // Sem vizinhos, mantém a curvatura original
          return;
        }

        let weightedSum = 0;
        let totalWeight = 0;

        // Calcula a nova curvatura baseada nos vizinhos
        neighbors.forEach((neighbor) => {
          const neighborKey = `${neighbor.x},${neighbor.y},${neighbor.z}`;
          const neighborCurvature = curvatureMap.get(neighborKey);
          if (neighborCurvature !== undefined) {
            const distance = new THREE.Vector3(key.x, key.y, key.z).distanceTo(
              neighbor
            ); // Distância entre vértices
            const weight = this.gaussianWeight(distance, sigma);

            weightedSum += neighborCurvature * weight;
            totalWeight += weight;
          }
        });

        // Calcula a nova curvatura suavizada
        const smoothedCurvature = weightedSum / totalWeight;
        filteredCurvatureMap.set(key, smoothedCurvature);
      });

      return filteredCurvatureMap;
    },
    normalizeCurvatureMap(curvatureMap) {
      let minCurvature = Infinity;
      let maxCurvature = -Infinity;

      // Encontra os valores mínimo e máximo de curvatura
      curvatureMap.forEach((curvature) => {
        if (curvature < minCurvature) minCurvature = curvature;
        if (curvature > maxCurvature) maxCurvature = curvature;
      });

      // Normaliza o mapa de curvatura
      const normalizedCurvatureMap = new Map();
      curvatureMap.forEach((curvature, key) => {
        const normalizedCurvature =
          (curvature - minCurvature) / (maxCurvature - minCurvature);
        normalizedCurvatureMap.set(key, normalizedCurvature);
      });

      return normalizedCurvatureMap;
    },
    cascadeApplyCurvatureFilter(
      curvatureMap,
      iterations = 1,
      boundaryThreshold = 0.7,
      reductionFactor = 0.3
    ) {
      let currentCurvatureMap = this.normalizeCurvatureMap(curvatureMap); // Normaliza o mapa inicial

      for (let i = 0; i < iterations; i++) {
        // Aplica o filtro de suavização a cada iteração
        currentCurvatureMap = this.applyCurvatureFilter(
          currentCurvatureMap,
          boundaryThreshold,
          reductionFactor
        );

        // Normaliza o mapa de curvatura após cada iteração
        currentCurvatureMap = this.normalizeCurvatureMap(currentCurvatureMap);
      }

      return currentCurvatureMap;
    },
    applyCurvatureFilter(
      curvatureMap,
      boundaryThreshold = 0.7,
      reductionFactor = 0.3
    ) {
      const filteredCurvatureMap = new Map();

      curvatureMap.forEach((curvature, key) => {
        let filteredCurvature;

        if (curvature > boundaryThreshold) {
          // Mantemos a curvatura em áreas de interesse
          filteredCurvature = curvature * (1 + 1 - reductionFactor);
        } else {
          // Aplicamos uma redução controlada nas áreas de menor interesse
          filteredCurvature = curvature * reductionFactor; // O fator pode ser ajustado
        }

        filteredCurvatureMap.set(key, filteredCurvature);
      });

      return filteredCurvatureMap;
    },
    visualizeFilteredCurvature(mesh, filteredCurvatureMap) {
      const positions = mesh.geometry.attributes.position;
      const colors = new Float32Array(positions.count * 3); // Array de cores (r, g, b para cada vértice)

      // Encontrar os valores mínimo e máximo de curvatura para normalizar
      let minCurvature = Infinity;
      let maxCurvature = -Infinity;

      filteredCurvatureMap.forEach((curvature) => {
        if (curvature < minCurvature) minCurvature = curvature;
        if (curvature > maxCurvature) maxCurvature = curvature;
      });

      // Função para normalizar curvatura (entre 0 e 1)
      const normalizeCurvature = (curvature) => {
        return (curvature - minCurvature) / (maxCurvature - minCurvature);
      };

      // Função para obter cor com base em valor normalizado (0 = azul, 1 = vermelho)
      const getColorFromCurvature = (normalizedCurvature) => {
        const color = new THREE.Color();
        color.setHSL((1 - normalizedCurvature) * 0.67, 1.0, 0.5); // 0.67 = azul, 0 = vermelho
        return color;
      };

      // Atribuir cores aos vértices com base na curvatura filtrada
      for (let i = 0; i < positions.count; i++) {
        const vertex = new THREE.Vector3(
          positions.getX(i),
          positions.getY(i),
          positions.getZ(i)
        );

        const key = `${vertex.x},${vertex.y},${vertex.z}`;
        const filteredCurvature = filteredCurvatureMap.get(key);

        // Normalizar o valor de curvatura filtrada
        const normalizedCurvature = normalizeCurvature(filteredCurvature);

        // Obter cor a partir do valor de curvatura
        const color = getColorFromCurvature(normalizedCurvature);

        // Definir a cor no array de cores
        colors[i * 3] = color.r;
        colors[i * 3 + 1] = color.g;
        colors[i * 3 + 2] = color.b;
      }

      // Atribuir as cores ao atributo 'color' da geometria
      mesh.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

      // Definir o material para usar a cor do vértice
      mesh.material = new THREE.MeshBasicMaterial({
        vertexColors: true,
        side: THREE.DoubleSide,
      });

      // Atualizar o mesh na cena
      mesh.geometry.attributes.color.needsUpdate = true;
    },
    createAdjacencyMap2(mesh) {
      const adjacencyMap = new Map();
      const positions = mesh.geometry.attributes.position;

      // Percorre cada triângulo do mesh
      for (let i = 0; i < positions.count; i += 3) {
        // Coletar os três vértices do triângulo atual
        const v1 = new THREE.Vector3(
          positions.getX(i),
          positions.getY(i),
          positions.getZ(i)
        );
        const v2 = new THREE.Vector3(
          positions.getX(i + 1),
          positions.getY(i + 1),
          positions.getZ(i + 1)
        );
        const v3 = new THREE.Vector3(
          positions.getX(i + 2),
          positions.getY(i + 2),
          positions.getZ(i + 2)
        );

        // Adiciona a adjacência de cada vértice
        this.addAdjacency(adjacencyMap, v1, v2);
        this.addAdjacency(adjacencyMap, v1, v3);
        this.addAdjacency(adjacencyMap, v2, v3);
      }

      return adjacencyMap;
    },
    // Função auxiliar para adicionar adjacência
    addAdjacency(adjacencyMap, vertexA, vertexB) {
      const keyA = `${vertexA.x},${vertexA.y},${vertexA.z}`;
      const keyB = `${vertexB.x},${vertexB.y},${vertexB.z}`;

      // Se o vértice A ainda não está no mapa, inicializa
      if (!adjacencyMap.has(keyA)) {
        adjacencyMap.set(keyA, []);
      }
      // Se o vértice B ainda não está no mapa, inicializa
      if (!adjacencyMap.has(keyB)) {
        adjacencyMap.set(keyB, []);
      }

      // Adiciona o vértice B à lista de adjacentes de A, e vice-versa
      adjacencyMap.get(keyA).push(vertexB);
      adjacencyMap.get(keyB).push(vertexA);
    },
    findClosestVertexCurvature(point, curvatureMap, thresholdDistance = 0.001) {
      let closestCurvature = undefined;
      let closestDistance = Infinity;

      // Itera sobre as chaves (vértices) no mapa de curvatura
      curvatureMap.forEach((curvature, key) => {
        const [x, y, z] = key.split(",").map(parseFloat);
        const vertex = new THREE.Vector3(x, y, z);

        // Calcula a distância entre o ponto e o vértice
        const distance = point.distanceTo(vertex);

        // Verifica se esse vértice está mais próximo que os anteriores e dentro do limite aceitável
        if (distance < closestDistance && distance < thresholdDistance) {
          closestDistance = distance;
          closestCurvature = curvature;
        }
      });

      return closestCurvature;
    },
    calculateCurvatureMap(mesh, adjacencyMap) {
      const curvatureMap = new Map();
      const positions = mesh.geometry.attributes.position;
      const normals = mesh.geometry.attributes.normal;

      // Para cada vértice, calcular a curvatura média
      for (let i = 0; i < positions.count; i++) {
        const vertex = new THREE.Vector3(
          positions.getX(i),
          positions.getY(i),
          positions.getZ(i)
        );
        const normal = new THREE.Vector3(
          normals.getX(i),
          normals.getY(i),
          normals.getZ(i)
        );

        // Usar o mapa de adjacência
        const neighbors = adjacencyMap.get(
          `${vertex.x},${vertex.y},${vertex.z}`
        );
        let curvature = 0;

        neighbors.forEach((neighbor) => {
          const diff = new THREE.Vector3().subVectors(vertex, neighbor);
          const dotProduct = normal.dot(diff.normalize());
          curvature += Math.abs(dotProduct);
        });

        // Usar a chave baseada nas coordenadas
        const key = `${vertex.x},${vertex.y},${vertex.z}`;
        curvatureMap.set(key, curvature / neighbors.length); // Média da curvatura
      }

      return curvatureMap;
    },
    calculateVertexCurvature(vertex, normal, mesh) {
      const neighbors = this.getAdjacentVertices(vertex, mesh); // Método para obter vértices adjacentes
      let curvature = 0;

      neighbors.forEach((neighbor) => {
        const diff = new THREE.Vector3().subVectors(vertex, neighbor);
        const dotProduct = normal.dot(diff.normalize());

        // A soma das projeções normais entre os vértices
        curvature += Math.abs(dotProduct);
      });

      return curvature / neighbors.length; // Média da curvatura
    },
    getAdjacentVertices(vertex, mesh) {
      const adjacentVertices = [];
      const positions = mesh.geometry.attributes.position;

      // Vamos iterar sobre todos os triângulos do mesh
      for (let i = 0; i < positions.count; i += 3) {
        // Vértices do triângulo atual
        const v1 = new THREE.Vector3(
          positions.getX(i),
          positions.getY(i),
          positions.getZ(i)
        );
        const v2 = new THREE.Vector3(
          positions.getX(i + 1),
          positions.getY(i + 1),
          positions.getZ(i + 1)
        );
        const v3 = new THREE.Vector3(
          positions.getX(i + 2),
          positions.getY(i + 2),
          positions.getZ(i + 2)
        );

        // Verifica se o vértice atual faz parte do triângulo
        if (vertex.equals(v1) || vertex.equals(v2) || vertex.equals(v3)) {
          // Adiciona os outros dois vértices como vizinhos
          if (!vertex.equals(v1)) adjacentVertices.push(v1);
          if (!vertex.equals(v2)) adjacentVertices.push(v2);
          if (!vertex.equals(v3)) adjacentVertices.push(v3);
        }
      }

      return adjacentVertices;
    },
    visualizeCurvature(mesh, curvatureMap) {
      const positions = mesh.geometry.attributes.position;
      const colors = new Float32Array(positions.count * 3); // Array de cores (r, g, b para cada vértice)

      // Encontrar os valores mínimo e máximo de curvatura para normalizar
      let minCurvature = Infinity;
      let maxCurvature = -Infinity;

      curvatureMap.forEach((curvature) => {
        if (curvature < minCurvature) minCurvature = curvature;
        if (curvature > maxCurvature) maxCurvature = curvature;
      });

      // Função para normalizar curvatura (entre 0 e 1)
      const normalizeCurvature = (curvature) => {
        return (curvature - minCurvature) / (maxCurvature - minCurvature);
      };

      // Função para obter cor com base em valor normalizado (0 = azul, 1 = vermelho)
      const getColorFromCurvature = (normalizedCurvature) => {
        const color = new THREE.Color();
        color.setHSL((1 - normalizedCurvature) * 0.67, 1.0, 0.5); // 0.67 = azul, 0 = vermelho
        return color;
      };

      // Atribuir cores aos vértices com base na curvatura
      for (let i = 0; i < positions.count; i++) {
        const vertex = new THREE.Vector3(
          positions.getX(i),
          positions.getY(i),
          positions.getZ(i)
        );

        const key = `${vertex.x},${vertex.y},${vertex.z}`;
        const curvature = curvatureMap.get(key);

        // Normalizar o valor de curvatura
        const normalizedCurvature = normalizeCurvature(curvature);

        // Obter cor a partir do valor de curvatura
        const color = getColorFromCurvature(normalizedCurvature);

        // Definir a cor no array de cores
        colors[i * 3] = color.r;
        colors[i * 3 + 1] = color.g;
        colors[i * 3 + 2] = color.b;
      }

      // Atribuir as cores ao atributo 'color' da geometria
      mesh.geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

      // Definir o material para usar a cor do vértice
      mesh.material = new THREE.MeshBasicMaterial({
        vertexColors: true,
        side: THREE.DoubleSide,
      });

      // Atualizar o mesh na cena
      mesh.geometry.attributes.color.needsUpdate = true;
    },
    filterLinesByCurvature(
      lines,
      curvatureMap,
      curvatureThreshold = 0.5,
      thresholdDistance = 0.1
    ) {
      const filteredLines = [];

      lines.forEach((lineGroup) => {
        const filteredGroup = lineGroup.filter((line) => {
          // Busca a curvatura do vértice mais próximo para os pontos de início e fim da linha
          const startCurvature = this.findClosestVertexCurvature(
            line.start,
            curvatureMap,
            thresholdDistance
          );
          const endCurvature = this.findClosestVertexCurvature(
            line.end,
            curvatureMap,
            thresholdDistance
          );

          // Mantém a linha se a curvatura de um dos extremos for maior que o limite
          return (
            (startCurvature !== undefined &&
              startCurvature > curvatureThreshold) ||
            (endCurvature !== undefined && endCurvature > curvatureThreshold)
          );
        });

        // Adiciona o grupo filtrado se houver linhas que atendem aos critérios
        if (filteredGroup.length > 0) {
          filteredLines.push(filteredGroup);
        }
      });

      return filteredLines;
    },

    exportToCSV(angleDataArray) {
      // Array para armazenar as linhas de texto
      const lines = [];

      // Cabeçalho (os nomes das propriedades)
      const headers = [
        "indexGroup",
        "index",
        "angle",
        "lastAngle",
        "difference",
        "totalDifference",
        "angleThreshold",
        "lineStartX",
        "lineStartY",
        "lineStartZ",
        "lineEndX",
        "lineEndY",
        "lineEndZ",
        "distance",
      ];

      // Adiciona o cabeçalho como primeira linha
      lines.push(headers.join("\t"));

      // Percorre cada objeto no array e adiciona os valores no formato desejado
      angleDataArray.forEach((item) => {
        let line = [
          item.indexGroup,
          item.index,
          item.angle,
          item.lastAngle,
          item.difference,
          item.totalDifference,
          item.angleThreshold,
          item.lineStartX,
          item.lineStartY,
          item.lineStartZ,
          item.lineEndX,
          item.lineEndY,
          item.lineEndZ,
          item.distance,
        ].join("\t"); // Une os valores com ponto e vírgula

        // Substitui os pontos por vírgulas
        line = line.replace(/\./g, ",");
        lines.push(line);
      });

      // Concatena todas as linhas em uma única string separada por quebras de linha
      return lines.join("\n");
    },
    plotIntersectionLines(scene, lines, planeType) {
      const group = new THREE.Group(); // Cria um grupo para armazenar todas as linhas
      const materialNormal = new THREE.LineBasicMaterial({ color: 0x0000ff }); // Azul para transições suaves
      const materialSharp = new THREE.LineBasicMaterial({ color: 0xffff00 }); // Vermelho para mudanças bruscas
      const sphereStart = new THREE.LineBasicMaterial({ color: 0xffff00 });
      //const sphereEnd = new THREE.LineBasicMaterial({ color: 0xff00ff });

      // Limite de mudança de direção (em radianos)
      const angleThreshold = Math.PI / 12; // 30 graus, você pode ajustar isso

      const angleData = []; // Array para armazenar os ângulos calculados

      lines.forEach((intersectionGroup, indexGroup) => {
        let previousDirection = null;
        let lastAngleDifference = null;

        // Array para armazenar as três últimas diferenças de ângulo
        const angleDifferences = [];

        intersectionGroup.forEach((line3, index) => {
          const points = [line3.start, line3.end]; // Os pontos da linha
          const geometry = new THREE.BufferGeometry().setFromPoints(points);
          const distance = line3.start.distanceTo(line3.end);

          // Cálculo do vetor de direção da linha atual
          let currentDirection = new THREE.Vector3()
            .subVectors(line3.end, line3.start)
            .normalize();

          // Zerar a componente do eixo de deslocamento com base no plano
          switch (planeType) {
            case "XY":
              currentDirection.z = 0; // Focamos na variação em XY, ignorando Z
              currentDirection.x = 0; // Focamos na variação em Z, ignorando Y
              break;
            case "XZ":
              currentDirection.y = 0; // Focamos na variação em XZ, ignorando Y
              currentDirection.x = 0; // Focamos na variação em Z, ignorando X
              break;
            case "YZ":
              currentDirection.x = 0; // Focamos na variação em YZ, ignorando X
              currentDirection.y = 0; // Focamos na variação em Z, ignorando Y
              break;
          }

          let material = materialNormal; // Material padrão (azul)
          let adjustedAngle;

          if (index > 0 && previousDirection) {
            // Cálculo do produto escalar para o ângulo entre os dois vetores
            const dotProduct = currentDirection.dot(previousDirection);
            const angle = Math.acos(THREE.MathUtils.clamp(dotProduct, -1, 1)); // Ângulo entre as direções

            // Verifica se o ângulo é maior que 90 graus (ou π/2 radianos)
            adjustedAngle = angle;
            if (angle > Math.PI / 2) {
              adjustedAngle = angle; // Ajusta para 180º menos o ângulo
            }

            // Salvando a diferença de ângulo atual
            const angleDifference = Math.abs(
              adjustedAngle - lastAngleDifference
            );

            // Adiciona a diferença ao array
            angleDifferences.push(angleDifference);

            // Se tivermos mais de 3 elementos, remove o primeiro (mantém as 3 últimas)
            if (angleDifferences.length > 2) {
              angleDifferences.shift(); // Remove o primeiro
            }

            // Calcula a soma das três últimas diferenças
            const totalDifference = angleDifferences.reduce(
              (acc, diff) => acc + diff,
              0
            );

            // Salvando o ângulo no array
            angleData.push({
              indexGroup: indexGroup, // Adiciona o índice do grupo
              index: index,
              angle: ((adjustedAngle * 180) / Math.PI).toFixed(4),
              lastAngle: ((lastAngleDifference * 180) / Math.PI).toFixed(4),
              difference: (
                (Math.abs(adjustedAngle - lastAngleDifference) * 180) /
                Math.PI
              ).toFixed(4),
              totalDifference, // Soma das últimas 3 diferenças
              angleThreshold,
              lineStartX: line3.start.x,
              lineStartY: line3.start.y,
              lineStartZ: line3.start.z,
              lineEndX: line3.end.x,
              lineEndY: line3.end.y,
              lineEndZ: line3.end.z,
              distance: distance, // Adiciona a distância calculada
            });

            // Se a soma das últimas três diferenças for maior que o limite, usa material vermelho
            if (totalDifference > angleThreshold) {
              material = materialSharp; // Mudança brusca detectada, usa material vermelho

              // Criar esferas nos pontos de início e fim da linha
              const sphereGeometry = new THREE.SphereGeometry(0.25, 4, 4); // Pequena esfera com raio 0.1

              // Cria a esfera no ponto 'start'
              const startSphere = new THREE.Mesh(sphereGeometry, sphereStart);
              startSphere.position.copy(line3.start); // Define a posição da esfera em 'start'
              group.add(startSphere); // Adiciona ao grupo

              // // Cria a esfera no ponto 'end'
              // const endSphere = new THREE.Mesh(sphereGeometry, sphereEnd);
              // endSphere.position.copy(line3.end); // Define a posição da esfera em 'end'
              // group.add(endSphere); // Adiciona ao grupo
            } else {
              // Criar esferas nos pontos de início e fim da linha
              // const sphereGeometry = new THREE.SphereGeometry(0.05, 4, 4); // Pequena esfera com raio 0.1
              // const endSphere = new THREE.Mesh(sphereGeometry, sphereEnd);
              // endSphere.position.copy(line3.end); // Define a posição da esfera em 'end'
              // group.add(endSphere); // Adiciona ao grupo
            }
          }

          // Cria a linha e a adiciona ao grupo
          // eslint-disable-next-line no-unused-vars
          const line = new THREE.Line(geometry, material);
          group.add(line);

          // Atualiza a direção anterior
          previousDirection = currentDirection.clone();
          lastAngleDifference = adjustedAngle;
        });
      });

      scene.add(group); // Adiciona o grupo inteiro à cena

      // Retorna os dados dos ângulos calculados
      return angleData;
    },
    groupSegmentsByAngle(lines, angleThreshold = 10, maxLineLength = 5) {
      const toDegrees = (radians) => (radians * 180) / Math.PI;

      // Converte as linhas ordenadas em segmentos agrupados
      const groupedLines = [];

      lines.forEach((lineGroup) => {
        if (lineGroup.length === 0) return;

        const mergedGroup = [];
        let currentLine = lineGroup[0]; // Começamos com o primeiro segmento

        for (let i = 1; i < lineGroup.length; i++) {
          const nextLine = lineGroup[i];

          // Vetores direção das duas linhas
          const direction1 = new THREE.Vector3()
            .subVectors(currentLine.end, currentLine.start)
            .normalize();
          const direction2 = new THREE.Vector3()
            .subVectors(nextLine.end, nextLine.start)
            .normalize();

          // Ângulo entre as duas direções usando o produto escalar
          const angleRadians = Math.acos(direction1.dot(direction2));
          const angleDegrees = toDegrees(angleRadians);

          // Se o ângulo entre as retas for menor que o limite, fundimos as retas
          if (angleDegrees <= angleThreshold) {
            // Atualizamos a linha corrente para combinar o início da primeira com o fim da próxima
            currentLine = {
              start: currentLine.start, // Início da primeira linha
              end: nextLine.end, // Fim da última linha dentro do limite angular
            };
          } else {
            // Se o ângulo for maior, adicionamos a linha atual ao grupo e começamos uma nova
            mergedGroup.push(currentLine);
            currentLine = nextLine;
          }
        }

        // Adicionar o último segmento após o loop
        mergedGroup.push(currentLine);

        // Adicionar o grupo processado ao array final
        groupedLines.push(mergedGroup);
      });

      // Calcular a redução percentual
      const initialCount = lines.flat().length;
      const groupedCount = groupedLines.flat().length;
      const reductionPercentage =
        ((initialCount - groupedCount) / initialCount) * 100;

      // Exibir a redução no console
      console.log(`Redução de segmentos: ${reductionPercentage.toFixed(2)}%`);

      // Filtrar e remover linhas longas
      const filteredGroupedLines = groupedLines.map((group) =>
        group.filter((line, index) => {
          const lineLength = line.start.distanceTo(line.end);

          // Se a linha for maior que o limite definido, emitimos um aviso no console e a removemos
          if (lineLength > maxLineLength) {
            console.warn(
              `Linha longa detectada! Comprimento: ${lineLength.toFixed(
                2
              )}, Índice: ${index}`
            );
            console.warn(
              `Detalhes da linha: Início ${line.start}, Fim ${line.end}`
            );
            return false; // Linha será removida
          }
          return true; // Linha será mantida
        })
      );

      return filteredGroupedLines;
    },

    sortIntersectionLines(lines) {
      const sortedLines = [];

      lines.forEach((intersectionGroup) => {
        if (intersectionGroup.length > 0) {
          const orderedGroup = [];

          // Começamos com o primeiro segmento de reta do grupo
          orderedGroup.push(intersectionGroup[0]);
          intersectionGroup.splice(0, 1);

          // Continuamos organizando até que todos os segmentos sejam usados
          while (intersectionGroup.length > 0) {
            const lastLine = orderedGroup[orderedGroup.length - 1];
            const lastEnd = lastLine.end;

            // Encontrar o segmento mais próximo ao último ponto de extremidade
            let closestIndex = -1;
            let closestDistance = Infinity;
            let shouldReverse = false;

            intersectionGroup.forEach((line, index) => {
              const distanceToStart = lastEnd.distanceTo(line.start);
              const distanceToEnd = lastEnd.distanceTo(line.end);

              // Verifica qual extremidade está mais próxima
              if (distanceToStart < closestDistance) {
                closestDistance = distanceToStart;
                closestIndex = index;
                shouldReverse = false;
              }

              if (distanceToEnd < closestDistance) {
                closestDistance = distanceToEnd;
                closestIndex = index;
                shouldReverse = true;
              }
            });

            // Agora, adicionamos o segmento mais próximo na lista ordenada
            const closestLine = intersectionGroup.splice(closestIndex, 1)[0];

            // Precisamos garantir que a orientação da linha corresponda
            if (shouldReverse) {
              // Inverte a linha se necessário para manter a conexão
              orderedGroup.push({
                start: closestLine.end,
                end: closestLine.start,
              });
            } else {
              orderedGroup.push(closestLine);
            }
          }

          // Adiciona o grupo ordenado ao array final
          sortedLines.push(orderedGroup);
        }
      });

      return sortedLines;
    },

    sortIntersectionLines_(lines) {
      const sortedLines = [];

      lines.forEach((intersectionGroup) => {
        if (intersectionGroup.length > 0) {
          const orderedGroup = [];

          // Começamos com o primeiro segmento de reta do grupo
          orderedGroup.push(intersectionGroup[0]);
          intersectionGroup.splice(0, 1);

          // Continuamos organizando até que todos os segmentos sejam usados
          while (intersectionGroup.length > 0) {
            const lastLine = orderedGroup[orderedGroup.length - 1];
            const lastEnd = lastLine.end;

            // Encontrar o segmento mais próximo ao último ponto de extremidade
            let closestIndex = -1;
            let closestDistance = Infinity;

            intersectionGroup.forEach((line, index) => {
              const distanceToStart = lastEnd.distanceTo(line.start);
              const distanceToEnd = lastEnd.distanceTo(line.end);

              // Vamos considerar o menor entre a distância até o início ou o fim da linha
              if (distanceToStart < closestDistance) {
                closestDistance = distanceToStart;
                closestIndex = index;
              }

              if (distanceToEnd < closestDistance) {
                closestDistance = distanceToEnd;
                closestIndex = index;
              }
            });

            // Agora, adicionamos o segmento mais próximo na lista ordenada
            const closestLine = intersectionGroup.splice(closestIndex, 1)[0];

            // Precisamos garantir que a orientação da linha corresponda
            if (
              lastEnd.distanceTo(closestLine.start) <
              lastEnd.distanceTo(closestLine.end)
            ) {
              orderedGroup.push(closestLine);
            } else {
              // Inverte a linha se necessário para manter a conexão
              orderedGroup.push({
                start: closestLine.end,
                end: closestLine.start,
              });
            }
          }

          // Adiciona o grupo ordenado ao array final
          sortedLines.push(orderedGroup);
        }
      });

      return sortedLines;
    },
    calculateIntersectionLines(mesh, planeType, step, direction) {
      const lines = []; // Armazenará as linhas de interseção

      //  mesh.scale.set(1, 1, -1); // Espelha a geometria no eixo Z
      //  mesh.updateMatrixWorld(true); // Atualiza a matriz do mundo após a escala

      const geometry = mesh.geometry;

      // Inicializando o BVH
      MeshBVH.prototype.acceleratedRaycast = acceleratedRaycast;
      const bvh = new MeshBVH(geometry); // Cria o BVH para otimização

      // Definindo a direção do plano e posição inicial
      //const boundingBox = new THREE.Box3().setFromObject(mesh);
      const boundingBox = mesh.userData.helpers.boxHelper.box.clone();
      const plane = new THREE.Plane(); // Plano de corte

      let tempZ = boundingBox.min.y;
      boundingBox.min.y = -boundingBox.max.y;
      boundingBox.max.y = -tempZ;

      let planePosition;
      switch (planeType) {
        case "XY": // Plano XY deslocando em Z
          plane.normal.set(0, 0, 1);
          planePosition = boundingBox.min.z;
          break;
        case "XZ": // Plano XZ deslocando em Y
          plane.normal.set(0, 1, 0);
          planePosition = boundingBox.min.y;
          break;
        case "YZ": // Plano YZ deslocando em X
          plane.normal.set(1, 0, 0);
          planePosition = boundingBox.min.x;
          break;
        default:
          throw new Error("Plano inválido");
      }

      // Iterar através do volume da geometria para buscar interseções
      const planeMax = boundingBox.max[direction];

      for (
        let currentPos = planePosition;
        currentPos <= planeMax;
        currentPos += step
      ) {
        // Atualizando a posição do plano
        plane.constant = currentPos;

        // Calculando as interseções usando BVH
        const intersections = this.getBVHPlaneIntersections(
          geometry,
          plane,
          bvh
        );

        if (intersections.length > 0) {
          lines.push(intersections);
        }
      }

      return lines;
    },

    // Método otimizado para interseções com BVH
    getBVHPlaneIntersections(geometry, plane, bvh) {
      //const lines = [];
      //const positionAttribute = geometry.getAttribute('position');

      const intersects = [];
      bvh.shapecast({
        intersectsBounds: (box) => plane.intersectsBox(box), // Testa se o box do BVH cruza o plano
        intersectsTriangle: (tri) => {
          const { a, b, c } = tri; // Os três vértices do triângulo

          // Verificando se o triângulo cruza o plano
          const line = this.calculateTrianglePlaneIntersection(a, b, c, plane);

          if (line) {
            intersects.push(line);
          }
        },
      });

      return intersects;
    },

    // O mesmo método para calcular interseção de triângulo e plano
    calculateTrianglePlaneIntersection(v1, v2, v3, plane) {
      const intersectionPoints = [];

      this.checkEdgeIntersection(v1, v2, plane, intersectionPoints);
      this.checkEdgeIntersection(v2, v3, plane, intersectionPoints);
      this.checkEdgeIntersection(v3, v1, plane, intersectionPoints);

      if (intersectionPoints.length === 2) {
        return new THREE.Line3(intersectionPoints[0], intersectionPoints[1]);
      }

      return null;
    },

    // O mesmo método para verificar interseção de aresta
    checkEdgeIntersection(v1, v2, plane, intersectionPoints) {
      const distance1 = plane.distanceToPoint(v1);
      const distance2 = plane.distanceToPoint(v2);

      if (distance1 * distance2 < 0) {
        const t = distance1 / (distance1 - distance2); // Interpolação linear
        const intersection = new THREE.Vector3().lerpVectors(v1, v2, t);
        intersectionPoints.push(intersection);
      }
    },
    // Função para obter a normal do triângulo clicado a partir de intersection
    getTriangleNormalFromIntersection(intersection, normals) {
      const index = intersection.index * 3; // Multiplicado por 3 pois são 3 componentes por vértice (x, y, z)

      // Pegar as três componentes da normal a partir do índice
      const normalX = normals[index];
      const normalY = normals[index + 1];
      const normalZ = normals[index + 2];

      return new THREE.Vector3(normalX, normalY, normalZ).normalize();
    },
    // Função para criar uma esfera azul translúcida
    createTranslucentSphere(position, radius, color, opacity) {
      // Verifica se o mesh 'borderInfo' já existe na cena
      let borderInfo = this.scene.getObjectByName("borderInfo");

      // Se não existir, cria o mesh 'borderInfo' e adiciona à cena
      if (!borderInfo) {
        borderInfo = new THREE.Group();
        borderInfo.name = "borderInfo";
        borderInfo.userData.name = "borderInfo";
        this.scene.add(borderInfo);
      }

      // Cria a esfera translúcida
      const sphereGeometry = new THREE.SphereGeometry(radius, 8, 8);
      const sphereMaterial = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: opacity,
      });
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.position.copy(position);

      // Adiciona a esfera ao grupo 'borderInfo'
      borderInfo.add(sphere);

      return;
    },
    // Função melhorada para encontrar o vértice mais próximo usando adjacencyMap
    // Função para encontrar o vértice mais próximo de um ponto dado
    findNearestVertex(point, positions) {
      let nearestVertexIndex = null;
      let minDistance = Infinity;

      for (let i = 0; i < positions.length; i += 3) {
        const vertex = new THREE.Vector3(
          positions[i],
          positions[i + 1],
          positions[i + 2]
        );
        const distance = vertex.distanceTo(point);
        if (distance < minDistance) {
          minDistance = distance;
          nearestVertexIndex = i / 3; // Índice do vértice mais próximo
        }
      }

      return nearestVertexIndex;
    },

    // Função melhorada para encontrar vértices dentro de um raio, usando adjacencyMap
    findVerticesWithinRadius(point, radius, positions, adjacencyMap) {
      const verticesInRadius = [];
      const visited = new Set(); // Para evitar visitar o mesmo vértice mais de uma vez

      // Encontrar o vértice mais próximo do ponto
      const nearestVertexIndex = this.findNearestVertex(point, positions);

      // Pilha para realizar busca nos vértices adjacentes
      const stack = [nearestVertexIndex];

      while (stack.length > 0) {
        const currentVertexIndex = stack.pop();

        // Verifique se o vértice já foi visitado
        if (visited.has(currentVertexIndex)) continue;
        visited.add(currentVertexIndex);

        // Pegar as coordenadas do vértice
        const vertex = new THREE.Vector3(
          positions[currentVertexIndex * 3],
          positions[currentVertexIndex * 3 + 1],
          positions[currentVertexIndex * 3 + 2]
        );

        // Verificar se está dentro do raio
        if (vertex.distanceTo(point) <= radius) {
          verticesInRadius.push(currentVertexIndex);

          // Adicionar vizinhos à pilha para continuar a busca
          adjacencyMap[currentVertexIndex].forEach((neighborIndex) => {
            if (!visited.has(neighborIndex)) {
              stack.push(neighborIndex);
            }
          });
        }
      }

      return verticesInRadius;
    },

    // Adapte a função analyzeSpheres para usar o adjacencyMap
    // eslint-disable-next-line no-unused-vars
    analyzeSpheres(geometry, maxRadius = 0.25) {
      const borderInfo = this.scene.getObjectByName("borderInfo");
      if (!borderInfo) {
        console.warn("Mesh 'borderInfo' não encontrado.");
        return [];
      }

      // Criar o mapa de adjacência
      //const adjacencyMap = this.createAdjacencyMap(geometry);

      const sphereDataArray = [];

      borderInfo.children.forEach((sphere) => {
        const clickPoint = sphere.position;
        //const positions = geometry.attributes.position.array;

        // Encontrar os vértices dentro do raio, usando adjacencyMap
        // const verticesInRadius = this.findVerticesWithinRadius(
        //   clickPoint,
        //   maxRadius,
        //   positions,
        //   adjacencyMap
        // );
        // const vertexCount = verticesInRadius.length;

        // Formatar a posição para salvar corretamente
        const sphereInfo = {
          position: { x: clickPoint.x, y: clickPoint.y, z: clickPoint.z },
          vertexCount: 0,
          verticesInRadius: [],
        };

        sphereDataArray.push(sphereInfo);
      });

      return sphereDataArray;
    },
    findNearestVertex__(point, initialVertexIndex, positions, adjacencyMap) {
      let nearestVertexIndex = initialVertexIndex;
      let minDistance = Infinity;
      const visited = new Set();
      const stack = [initialVertexIndex];

      while (stack.length > 0) {
        const currentVertexIndex = stack.pop();

        // Verifique se o vértice já foi visitado
        if (visited.has(currentVertexIndex)) continue;
        visited.add(currentVertexIndex);

        // Pegar as coordenadas do vértice
        const vertex = new THREE.Vector3(
          positions[currentVertexIndex * 3],
          positions[currentVertexIndex * 3 + 1],
          positions[currentVertexIndex * 3 + 2]
        );

        // Calcular a distância para o ponto alvo
        const distance = vertex.distanceTo(point);
        if (distance < minDistance) {
          minDistance = distance;
          nearestVertexIndex = currentVertexIndex;
        }

        // Adicionar vizinhos à pilha para continuar a busca
        adjacencyMap[currentVertexIndex].forEach((neighborIndex) => {
          if (!visited.has(neighborIndex)) {
            stack.push(neighborIndex);
          }
        });
      }

      return nearestVertexIndex;
    },

    // Agora, na findVerticesWithinRadius, começamos a partir de um vértice inicial
    findVerticesWithinRadius__(point, radius, positions, adjacencyMap) {
      const verticesInRadius = [];
      const visited = new Set(); // Para evitar visitar o mesmo vértice mais de uma vez

      // Pegar um vértice inicial aleatório (poderia ser qualquer vértice válido, aqui estou pegando o primeiro)
      const initialVertexIndex = 0; // Escolha um índice inicial para começar

      // Encontrar o vértice mais próximo do ponto usando adjacencyMap
      const nearestVertexIndex = this.findNearestVertex(
        point,
        initialVertexIndex,
        positions,
        adjacencyMap
      );

      // Pilha para realizar busca nos vértices adjacentes a partir do vértice mais próximo
      const stack = [nearestVertexIndex];

      while (stack.length > 0) {
        const currentVertexIndex = stack.pop();

        // Verifique se o vértice já foi visitado
        if (visited.has(currentVertexIndex)) continue;
        visited.add(currentVertexIndex);

        // Pegar as coordenadas do vértice
        const vertex = new THREE.Vector3(
          positions[currentVertexIndex * 3],
          positions[currentVertexIndex * 3 + 1],
          positions[currentVertexIndex * 3 + 2]
        );

        // Verificar se está dentro do raio
        if (vertex.distanceTo(point) <= radius) {
          verticesInRadius.push(currentVertexIndex);

          // Adicionar vizinhos à pilha para continuar a busca
          adjacencyMap[currentVertexIndex].forEach((neighborIndex) => {
            if (!visited.has(neighborIndex)) {
              stack.push(neighborIndex);
            }
          });
        }
      }

      return verticesInRadius;
    },
    convertIndicesToMap(sphereDataArray, geometry) {
      const positions = geometry.attributes.position.array;

      // Função auxiliar para converter um índice em uma chave de posição
      function getPositionKey(index) {
        const x = positions[index * 3];
        const y = positions[index * 3 + 1];
        const z = positions[index * 3 + 2];
        return `${x},${y},${z}`;
      }

      const convertedMap = new Map();

      // Itera sobre cada esfera no array
      sphereDataArray.forEach((sphereData) => {
        const mainKey = `${sphereData.position.x},${sphereData.position.y},${sphereData.position.z}`;

        // Converte os índices dos vizinhos em chaves de posição
        const neighborsKeys = sphereData.verticesInRadius.map((index) =>
          getPositionKey(index)
        );

        // Adiciona ao map: a chave principal é a posição da esfera, o valor são as chaves dos vizinhos
        convertedMap.set(mainKey, neighborsKeys);
      });

      return convertedMap;
    },
    // Adapte a função analyzeSpheres para usar o adjacencyMap
    analyzeSpheres__(geometry, maxRadius = 0.25) {
      const borderInfo = this.scene.getObjectByName("borderInfo");
      if (!borderInfo) {
        console.warn("Mesh 'borderInfo' não encontrado.");
        return [];
      }

      // Criar o mapa de adjacência
      const adjacencyMap = this.createAdjacencyMap(geometry);

      const sphereDataArray = [];

      borderInfo.children.forEach((sphere) => {
        const clickPoint = sphere.position;
        const positions = geometry.attributes.position.array;

        // Encontrar os vértices dentro do raio, usando adjacencyMap
        const verticesInRadius = this.findVerticesWithinRadius(
          clickPoint,
          maxRadius,
          positions,
          adjacencyMap
        );
        const vertexCount = verticesInRadius.length;

        // Formatar a posição para salvar corretamente
        const sphereInfo = {
          position: { x: clickPoint.x, y: clickPoint.y, z: clickPoint.z },
          vertexCount: vertexCount,
          verticesInRadius: verticesInRadius,
        };

        sphereDataArray.push(sphereInfo);
      });

      return sphereDataArray;
    },

    analyzeSpheres_(geometry, maxRadius = 0.25) {
      const borderInfo = this.scene.getObjectByName("borderInfo");
      if (!borderInfo) {
        console.warn("Mesh 'borderInfo' não encontrado.");
        return [];
      }

      const sphereDataArray = [];

      borderInfo.children.forEach((sphere) => {
        const clickPoint = sphere.position;
        const positions = geometry.attributes.position.array;
        const verticesInRadius = this.findVerticesWithinRadius(
          clickPoint,
          maxRadius,
          positions
        );
        const vertexCount = verticesInRadius.length;

        // Formatar a posição para salvar corretamente
        const sphereInfo = {
          position: { x: clickPoint.x, y: clickPoint.y, z: clickPoint.z },
          vertexCount: vertexCount,
          verticesInRadius: verticesInRadius,
        };

        sphereDataArray.push(sphereInfo);
      });

      return sphereDataArray;
    },
    analyzeClick(intersection, geometry, maxRadius = 0.25) {
      // Ponto clicado
      const clickedPoint = intersection.point;

      // Criar esfera azul translúcida no ponto clicado
      this.createTranslucentSphere(clickedPoint, maxRadius, 0x00ff00, 0.5);

      // Acessar os vértices da BufferGeometry
      const positions = geometry.attributes.position.array;
      //const adjacencyMap = this.createAdjacencyMap(geometry);

      // Encontrar os vértices dentro do raio
      const verticesInRadius = this.findVerticesWithinRadius(
        clickedPoint,
        maxRadius,
        positions
      );

      // Contar a quantidade de vértices dentro do raio
      const vertexCount = verticesInRadius.length;

      return { clickedPoint, verticesInRadius, vertexCount };
    },

    // Função para encontrar vértices dentro de um raio específico
    findVerticesWithinRadius_(point, radius, positions) {
      const verticesInRadius = [];

      for (let i = 0; i < positions.length; i += 3) {
        const vertex = new THREE.Vector3(
          positions[i],
          positions[i + 1],
          positions[i + 2]
        );
        if (vertex.distanceTo(point) <= radius) {
          verticesInRadius.push(i / 3); // Guardar o índice do vértice
        }
      }

      return verticesInRadius;
    },

    // Função para criar o mapa de adjacência
    createAdjacencyMap(geometry) {
      const positions = geometry.attributes.position.array;
      const adjacencyMap = {};

      // Inicializar o mapa de adjacência
      for (let i = 0; i < geometry.attributes.position.count; i++) {
        adjacencyMap[i] = [];
      }

      // Percorrer os triângulos
      for (let i = 0; i < positions.length; i += 9) {
        // Cada triângulo tem 9 valores (3 vértices, 3 coordenadas cada)
        const a = i / 3;
        const b = (i + 3) / 3;
        const c = (i + 6) / 3;

        adjacencyMap[a].push(b, c);
        adjacencyMap[b].push(a, c);
        adjacencyMap[c].push(a, b);
      }

      // Remover duplicatas de vizinhos
      for (const key in adjacencyMap) {
        adjacencyMap[key] = [...new Set(adjacencyMap[key])];
      }

      return adjacencyMap;
    },

    // Função para encontrar os triângulos com base nos vértices encontrados
    findTrianglesFromVertices(vertices, adjacencyMap) {
      const triangles = [];

      vertices.forEach((vertex) => {
        const neighbors = adjacencyMap[vertex];
        for (let i = 0; i < neighbors.length; i++) {
          for (let j = i + 1; j < neighbors.length; j++) {
            triangles.push([vertex, neighbors[i], neighbors[j]]);
          }
        }
      });

      return triangles;
    },

    // Função para calcular a área de um triângulo
    calculateTriangleArea(triangle, positions) {
      const v0 = new THREE.Vector3(
        positions[triangle[0] * 3],
        positions[triangle[0] * 3 + 1],
        positions[triangle[0] * 3 + 2]
      );
      const v1 = new THREE.Vector3(
        positions[triangle[1] * 3],
        positions[triangle[1] * 3 + 1],
        positions[triangle[1] * 3 + 2]
      );
      const v2 = new THREE.Vector3(
        positions[triangle[2] * 3],
        positions[triangle[2] * 3 + 1],
        positions[triangle[2] * 3 + 2]
      );

      const area = 0.5 * v0.clone().sub(v1).cross(v0.clone().sub(v2)).length();
      return area;
    },

    // Função para obter a normal de um triângulo
    getTriangleNormal(triangle, geometry) {
      const normals = geometry.attributes.normal.array;
      const normal = new THREE.Vector3(
        normals[triangle[0] * 3],
        normals[triangle[0] * 3 + 1],
        normals[triangle[0] * 3 + 2]
      );
      return normal;
    },

    // Função para calcular o ângulo entre duas normais
    calculateAngleBetweenNormals(normal1, normal2) {
      return normal1.angleTo(normal2); // Retorna o ângulo em radianos
    },

    // Função para calcular a curvatura de um triângulo (baseado nas normais)
    calculateCurvature(triangle, adjacencyMap, geometry) {
      const neighborTriangles = this.getNeighborTriangles(
        triangle,
        adjacencyMap,
        geometry
      );
      const normal = this.getTriangleNormal(triangle, geometry);

      const curvature =
        neighborTriangles.reduce((sum, neighbor) => {
          const neighborNormal = this.getTriangleNormal(neighbor, geometry);
          return sum + normal.angleTo(neighborNormal);
        }, 0) / neighborTriangles.length;

      return curvature;
    },

    // Função para encontrar triângulos vizinhos de um triângulo
    getNeighborTriangles(triangle, adjacencyMap) {
      const neighborTriangles = new Set(); // Usamos um Set para evitar duplicados

      // Iterar sobre cada vértice do triângulo
      for (let i = 0; i < 3; i++) {
        const vertex = triangle[i];
        const neighbors = adjacencyMap[vertex];

        // Procurar pares de vizinhos que formam triângulos válidos
        for (let j = 0; j < neighbors.length; j++) {
          const neighbor1 = neighbors[j];

          for (let k = j + 1; k < neighbors.length; k++) {
            const neighbor2 = neighbors[k];

            // Formar o triângulo com os dois vizinhos e o vértice atual
            const newTriangle = [vertex, neighbor1, neighbor2];

            // Ordenar os vértices do triângulo para evitar duplicados (mesma combinação)
            newTriangle.sort((a, b) => a - b);

            // Converter para string e adicionar ao Set para evitar duplicatas
            neighborTriangles.add(newTriangle.join(","));
          }
        }
      }

      // Retornar como um array de triângulos
      return Array.from(neighborTriangles).map((tri) =>
        tri.split(",").map(Number)
      );
    },
    groupToothCentroidsWithKMeans(centroids, numClusters = 16) {
      // Converta os centroides para uma matriz de pontos
      const points = centroids.map((centroid) => [
        centroid.x,
        centroid.y,
        centroid.z,
      ]);

      // Execute o algoritmo K-means
      const result = KMeans(points, numClusters);

      const clusterCentroids = result.centroids.map((centroid) => {
        return new THREE.Vector3(
          centroid.centroid[0],
          centroid.centroid[1],
          centroid.centroid[2]
        );
      });

      return clusterCentroids;
    },

    matrixToTexture(matrix, width, height) {
      // Calcula o valor mínimo e máximo
      let min = Infinity,
        max = -Infinity;
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          if (matrix[y][x] < min) min = matrix[y][x];
          if (matrix[y][x] > max) max = matrix[y][x];
        }
      }

      const size = width * height;
      const data = new Uint8Array(4 * size);

      // Mapeia os valores para a escala de 0-255
      for (let i = 0; i < size; i++) {
        const y = Math.floor(i / width);
        const x = i % width;
        const normalized = (matrix[y][x] - min) / (max - min);
        const value = Math.floor(normalized * 255);
        const stride = i * 4;
        data[stride] = value;
        data[stride + 1] = value;
        data[stride + 2] = value;
        data[stride + 3] = 255;
      }

      const texture = new THREE.DataTexture(data, width, height);
      texture.needsUpdate = true;

      return texture;
    },

    addMatrixMesh(matrix) {
      const width = matrix[0].length;
      const height = matrix.length;
      const texture = this.matrixToTexture(matrix, width, height);

      const geometry = new THREE.PlaneGeometry(50, 50);
      const material = new THREE.MeshBasicMaterial({ map: texture });
      const mesh = new THREE.Mesh(geometry, material);
      this.scene.add(mesh);
    },

    highlightTeethRegions(geometry) {
      const plane = this.determineAlignmentAndFrontDirection(geometry).plane;

      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
      const mesh = new THREE.Mesh(geometry, material);

      const boundingBox = new THREE.Box3().setFromObject(mesh);

      let minX, maxX, minY, maxY, minZ, maxZ;

      if (plane === "xz") {
        minX = boundingBox.min.x;
        maxX = boundingBox.max.x;
        minY = boundingBox.min.y;
        maxY = boundingBox.max.y;
        minZ = boundingBox.min.z;
        maxZ = boundingBox.max.z;
      } else if (plane === "yz") {
        minX = boundingBox.min.y; // Para o plano yz, y é tratado como x
        maxX = boundingBox.max.y;
        minZ = boundingBox.min.x; // e x como z
        maxZ = boundingBox.max.x;
        minY = boundingBox.min.z;
        maxY = boundingBox.max.z;
      } else if (plane === "xy") {
        minX = boundingBox.min.x;
        maxX = boundingBox.max.x;
        minZ = boundingBox.min.z;
        maxZ = boundingBox.max.z;
        // eslint-disable-next-line no-unused-vars
        minY = boundingBox.min.y;
        // eslint-disable-next-line no-unused-vars
        maxY = boundingBox.max.y;
      }

      // Defina a dimensão da matriz para 512x512
      const width = 512;
      const height = 512;

      // Crie a matriz 2D para acumular as alturas
      const matrix = Array.from({ length: width }, () =>
        new Array(height).fill(0)
      );

      const positions = geometry.attributes.position.array;
      const numVertices = positions.length / 3;
      for (let i = 0; i < numVertices; i++) {
        const x = positions[i * 3];
        const y = positions[i * 3 + 1];
        const z = positions[i * 3 + 2];

        // Normaliza as coordenadas para o tamanho da matriz
        const coordX = Math.floor(((x - minX) / (maxX - minX)) * (width - 1));
        const coordY = Math.floor(((z - minZ) / (maxZ - minZ)) * (height - 1));

        let heightValue;
        if (plane === "xz") {
          heightValue = y;
        } else if (plane === "yz") {
          heightValue = x;
        } else if (plane === "xy") {
          heightValue = z;
        }

        // Acumula a altura na matriz
        if (coordX >= 0 && coordX < width && coordY >= 0 && coordY < height) {
          //const coord = Math.floor(((heightValue - minY) / (maxY - minY)) * (25 - 1));
          matrix[coordX][coordY] += heightValue;
        }
      }

      const teethRegions = [];
      // Identifica regiões de interesse
      for (let i = 0; i < width; i++) {
        for (let j = 0; j < height; j++) {
          if (matrix[i][j] < 0) {
            // Ajuste conforme necessário
            teethRegions.push({ x: i, y: j, height: matrix[i][j] });
          }
        }
      }
      this.addMatrixMesh(matrix);
      return teethRegions;
    },
    determineAlignmentAndFrontDirection(geometry) {
      // Cria um material e um mesh a partir da geometria
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
      const mesh = new THREE.Mesh(geometry, material);

      // Cria uma instância de Box3 para calcular a bounding box
      const boundingBox = new THREE.Box3().setFromObject(mesh);

      // Obtém os limites da bounding box
      const min = boundingBox.min;
      const max = boundingBox.max;

      // Calcula as extensões em cada eixo
      const extentX = max.x - min.x;
      const extentY = max.y - min.y;
      const extentZ = max.z - min.z;

      // Determina o plano e o eixo da altura
      let plane = "";
      let heightAxis = "";
      if (extentY < extentX && extentY < extentZ) {
        heightAxis = "y";
        plane = "xz";
      } else if (extentX < extentY && extentX < extentZ) {
        heightAxis = "x";
        plane = "yz";
      } else {
        heightAxis = "z";
        plane = "xy";
      }

      // Centraliza o modelo
      const center = boundingBox.getCenter(new THREE.Vector3());
      geometry.translate(-center.x, -center.y, -center.z);

      // Analisa a distribuição dos pontos para identificar a direção da frente
      const points = geometry.attributes.position.array;
      const pointDensity = [0, 0, 0, 0]; // +z, +x, -z, -x

      for (let i = 0; i < points.length; i += 3) {
        const x = points[i];
        const y = points[i + 1];
        const z = points[i + 2];
        let angle;

        if (plane === "xz") {
          angle = Math.atan2(x, z) * (180 / Math.PI);
        } else if (plane === "yz") {
          angle = Math.atan2(y, z) * (180 / Math.PI);
        } else if (plane === "xy") {
          angle = Math.atan2(x, y) * (180 / Math.PI);
        }

        if (angle >= -45 && angle < 45) {
          pointDensity[0]++; // +z
        } else if (angle >= 45 && angle < 135) {
          pointDensity[1]++; // +x
        } else if (angle >= 135 || angle < -135) {
          pointDensity[2]++; // -z
        } else {
          pointDensity[3]++; // -x
        }
      }

      const minDensity = Math.min(...pointDensity);
      const frontIndex = pointDensity.indexOf(minDensity);

      let front = "";
      switch (frontIndex) {
        case 0:
          front = "-" + plane[1];
          break;
        case 1:
          front = "-" + plane[0];
          break;
        case 2:
          front = "+" + plane[1];
          break;
        case 3:
          front = "+" + plane[0];
          break;
      }

      return { plane, height: heightAxis, front };
    },

    async simpleAnalysis() {
      const geometry = this.activeObject.geometry;

      // Inicializa o mapa de vértices
      const vertexMap = this.initializeVertexMapSimple(geometry);

      // Constrói o mapa de adjacência com base no mapa de vértices
      const adjacencyMap = this.buildAdjacencyMap(vertexMap);

      // Criação do mapa de probabilidade
      const probabilityMap = this.createProbabilityMap(
        geometry,
        vertexMap,
        adjacencyMap,
        "inferior", // ou 'superior'
        3 // Limite de proximidade
      );

      const threshold = 1; // Ajuste conforme necessário
      const { dentes, gengiva } = this.classifyTriangles(
        probabilityMap,
        threshold
      );

      console.log("Triângulos que são dentes:", dentes);
      console.log("Triângulos que são gengiva:", gengiva);

      // Encontrar os centros dos dentes
      const toothCenters = this.findToothCenters(geometry, dentes);
      console.log("Centros dos dentes:", toothCenters);

      // Agrupar os centros dos dentes usando K-means
      const groupedToothCenters = this.groupToothCentroidsWithKMeans(
        toothCenters,
        16
      );
      console.log("Centros dos dentes agrupados:", groupedToothCenters);

      // Aplica cores diferentes aos triângulos classificados como dentes e gengiva
      this.colorizeTriangles(geometry, dentes);

      // Adicionar esferas para visualizar os centros dos dentes
      this.addToothCentersToScene(groupedToothCenters.flat());
    },

    // Função para adicionar esferas na posição dos centros dos dentes
    addToothCentersToScene(toothCenters) {
      toothCenters.forEach((center) => {
        const geometry = new THREE.SphereGeometry(0.2, 32, 32);
        const material = new THREE.MeshBasicMaterial({ color: 0x0000ff });
        const sphere = new THREE.Mesh(geometry, material);
        sphere.position.copy(center);
        this.scene.add(sphere);
      });
    },
    calculateTriangleCentroid(vertices, triangleIndex) {
      const v1 = new THREE.Vector3().fromArray(vertices, triangleIndex * 9);
      const v2 = new THREE.Vector3().fromArray(vertices, triangleIndex * 9 + 3);
      const v3 = new THREE.Vector3().fromArray(vertices, triangleIndex * 9 + 6);

      const centroid = new THREE.Vector3(
        (v1.x + v2.x + v3.x) / 3,
        (v1.y + v2.y + v3.y) / 3,
        (v1.z + v2.z + v3.z) / 3
      );

      return centroid;
    },

    groupToothCentroids(centroids, proximidadeLimite = 2) {
      const groups = [];
      const visited = new Set();

      centroids.forEach((centroid, index) => {
        if (visited.has(index)) return;

        const group = [];
        const queue = [index];

        while (queue.length > 0) {
          const currentIndex = queue.shift(); // Usar shift() para garantir ordem correta de processamento
          if (visited.has(currentIndex)) continue;

          visited.add(currentIndex);
          group.push(centroids[currentIndex]);

          for (let i = 0; i < centroids.length; i++) {
            if (
              !visited.has(i) &&
              centroids[currentIndex].distanceTo(centroids[i]) <
                proximidadeLimite
            ) {
              queue.push(i);
            }
          }
        }

        groups.push(group);
      });

      return groups;
    },
    calculateGroupCentroid(group) {
      const sum = group.reduce(
        (acc, centroid) => acc.add(centroid),
        new THREE.Vector3()
      );
      return sum.divideScalar(group.length);
    },

    findToothCenters(geometry, dentes) {
      const vertices = geometry.attributes.position.array;
      const toothCenters = [];

      // Calcular o centroide de cada triângulo classificado como dente
      dentes.forEach((triangleIndex) => {
        const centroid = this.calculateTriangleCentroid(
          vertices,
          triangleIndex
        );
        toothCenters.push(centroid);
      });
      return toothCenters;
    },

    colorizeTriangles(geometry, dentes) {
      const colorGengiva = new THREE.Color(0xff0000); // Verde para gengiva
      const colorDente = new THREE.Color(0xffffff); // Vermelho para dentes

      // Inicializa o array de cores preenchendo com a cor de gengiva
      const colors = new Float32Array(geometry.attributes.position.count * 3);

      for (let i = 0; i < geometry.attributes.position.count; i++) {
        colors[i * 3] = colorGengiva.r;
        colors[i * 3 + 1] = colorGengiva.g;
        colors[i * 3 + 2] = colorGengiva.b;
      }

      // Ajusta as cores dos triângulos classificados como dentes
      dentes.forEach((index) => {
        colors[index * 9] = colorDente.r;
        colors[index * 9 + 1] = colorDente.g;
        colors[index * 9 + 2] = colorDente.b;
        colors[index * 9 + 3] = colorDente.r;
        colors[index * 9 + 4] = colorDente.g;
        colors[index * 9 + 5] = colorDente.b;
        colors[index * 9 + 6] = colorDente.r;
        colors[index * 9 + 7] = colorDente.g;
        colors[index * 9 + 8] = colorDente.b;
      });

      // Cria uma cópia da geometria original
      const coloredGeometry = geometry.clone();

      // Define o atributo de cor na geometria clonada
      coloredGeometry.setAttribute(
        "color",
        new THREE.Float32BufferAttribute(colors, 3)
      );

      // Cria um material com wireframe e cores por vértice
      const material = new THREE.MeshBasicMaterial({
        wireframe: false,
        vertexColors: true,
      });

      // Cria um novo mesh com a geometria colorida e o material
      const coloredMesh = new THREE.Mesh(coloredGeometry, material);

      // Adiciona o novo mesh à cena
      this.scene.add(coloredMesh);

      console.warn("Done");
    },

    // groupToothCentroidsWithKMeans(centroids, numClusters = 16) {
    //   // Converta os centroides para uma matriz de pontos
    //   const points = centroids.map(centroid => [centroid.x, centroid.y, centroid.z]);

    //   // Execute o K-means
    //   const kmeans = new KMeans({ k: numClusters });
    //   kmeans.fit(points);

    //   // Agrupe os centroides com base nas etiquetas
    //   const groups = Array.from({ length: numClusters }, () => []);
    //   points.forEach((point, index) => {
    //     const label = kmeans.predict([point])[0];
    //     groups[label].push(centroids[index]);
    //   });

    //   return groups;
    // },

    initializeVertexMapSimple(geometry) {
      console.table(geometry);
      const positions = geometry.attributes.position.array;
      const totalTriangles = positions.length / 9; // Cada triângulo tem 3 vértices
      const vertexMap = new Map();

      for (let i = 0; i < totalTriangles; i++) {
        const baseIndex = i * 9;
        for (let j = 0; j < 3; j++) {
          // Loop para cada vértice do triângulo
          const x = positions[baseIndex + j * 3];
          const y = positions[baseIndex + j * 3 + 1];
          const z = positions[baseIndex + j * 3 + 2];
          const key = `${x.toFixed(15)}-${y.toFixed(15)}-${z.toFixed(15)}`; // Reduzindo a precisão para evitar erros de ponto flutuante

          if (!vertexMap.has(key)) {
            vertexMap.set(key, []);
          }
          vertexMap.get(key).push(i);
        }
      }

      return vertexMap;
    },

    buildAdjacencyMap(vertexMap) {
      const adjacencyMap = new Map();

      for (let triangleIndex of vertexMap.values()) {
        for (let i = 0; i < triangleIndex.length; i++) {
          for (let j = i + 1; j < triangleIndex.length; j++) {
            const index1 = triangleIndex[i];
            const index2 = triangleIndex[j];

            if (!adjacencyMap.has(index1)) {
              adjacencyMap.set(index1, new Set());
            }
            adjacencyMap.get(index1).add(index2);

            if (!adjacencyMap.has(index2)) {
              adjacencyMap.set(index2, new Set());
            }
            adjacencyMap.get(index2).add(index1);
          }
        }
      }

      return adjacencyMap;
    },

    createProbabilityMap(
      geometry,
      vertexMap,
      adjacencyMap,
      tipoArcada,
      proximidadeLimite
    ) {
      const vertices = geometry.attributes.position.array;
      const alturaMedia = this.calculateMaxMinHeight(vertices, tipoArcada);
      const probabilidadeMap = [];

      for (let i = 0; i < vertices.length; i += 9) {
        const triangleIndex = i / 9;

        // Cálculo da concentração de triângulos dentro do limite de proximidade
        const concentracao = this.calculateConcentration(
          vertices,
          vertexMap.get(this.getKeyFromVertex(vertices, i)),
          adjacencyMap.get(triangleIndex),
          proximidadeLimite
        );

        // Ponderação pela altura
        const pesoAltura = this.calculateHeightWeight(
          new THREE.Vector3().fromArray(vertices, i),
          new THREE.Vector3().fromArray(vertices, i + 3),
          new THREE.Vector3().fromArray(vertices, i + 6),
          alturaMedia,
          tipoArcada
        );

        // Pontuação de probabilidade final para o triângulo
        const probabilidade = concentracao * (2 * pesoAltura);

        probabilidadeMap.push({
          indice: triangleIndex,
          probabilidade,
          pesoAltura,
          concentracao,
        });
      }

      return probabilidadeMap;
    },

    calculateConcentration(
      vertices,
      vertexIndices,
      neighbors,
      proximidadeLimite
    ) {
      let concentracao = 0;
      const tempVector = new THREE.Vector3(); // Vetor temporário para armazenar os vértices
      const tempNeighborVector = new THREE.Vector3(); // Vetor temporário para os vizinhos

      for (const index of neighbors) {
        // Obter o vértice do array de vértices
        const v = tempNeighborVector.fromArray(vertices, index * 3);

        // Verificar a distância euclidiana entre v e o vértice atual
        let isProximo = false;
        for (let i = 0; i < vertexIndices.length; i++) {
          const vi = tempVector.fromArray(vertices, vertexIndices[i] * 3); // Obtém o vértice correto do triângulo
          const distance = v.distanceTo(vi);
          if (distance < proximidadeLimite) {
            isProximo = true;
            break;
          }
        }

        if (isProximo) {
          concentracao++;
        }
      }

      return concentracao / neighbors.size;
    },
    calculateMaxMinHeight(vertices, tipoArcada) {
      let alturaExtrema = tipoArcada === "superior" ? -Infinity : Infinity;

      for (let i = 1; i < vertices.length; i += 3) {
        const y = vertices[i];
        if (tipoArcada === "superior" && y > alturaExtrema) {
          alturaExtrema = y;
        } else if (tipoArcada === "inferior" && y < alturaExtrema) {
          alturaExtrema = y;
        }
      }

      return Math.abs(alturaExtrema);
    },

    calculateHeightWeight(v1, v2, v3, alturaExtrema) {
      const alturaMediaTriangulo = Math.abs((v1.y + v2.y + v3.y) / 3);
      let alturaRelativa;

      alturaRelativa = (alturaExtrema - alturaMediaTriangulo) / alturaExtrema;

      return alturaRelativa;
    },
    classifyTriangles(probabilidadeMap, threshold) {
      const dentes = [];
      const gengiva = [];

      for (const item of probabilidadeMap) {
        if (Math.abs(item.probabilidade) > threshold) {
          dentes.push(item.indice);
        } else {
          gengiva.push(item.indice);
        }
      }

      return { dentes, gengiva };
    },

    getKeyFromVertex(vertices, index) {
      const precision = 15;
      const x = vertices[index];
      const y = vertices[index + 1];
      const z = vertices[index + 2];
      return `${x.toFixed(precision)}-${y.toFixed(precision)}-${z.toFixed(
        precision
      )}`;
    },
    async executeRaycasting() {
      this.statusMessage = "";
      this.removeBorderMesh();
      const contourMesh = this.findContourMesh();
      const tubeGeometries = this.findTubeGeometries(contourMesh);
      const selectedTriangles = this.selectCloseTriangles(
        this.activeObject,
        tubeGeometries,
        0.45
      );
      if (!selectedTriangles) {
        this.statusMessage = "A borda selecionada não está fechada.";
      } else {
        this.createMeshFromCloseTriangles(this.activeObject, selectedTriangles);
        const isUpperArc =
          this.activeObject.userData.modelType === "maxillary" ? true : false;
        const meshSegmenter = new MeshSegmenter(
          this.activeObject,
          selectedTriangles,
          isUpperArc,
          10
        );
        meshSegmenter.segment();
        console.table({
          candidate: meshSegmenter.candidateTriangles.length,
          border: meshSegmenter.borderTriangleIndices.length,
          segmented: meshSegmenter.segmentedTriangles.size,
          percent: (
            (100 * meshSegmenter.segmentedTriangles.size) /
            meshSegmenter.candidateTriangles.length
          ).toFixed(2),
        });
        if (
          meshSegmenter.segmentedTriangles.size /
            meshSegmenter.candidateTriangles.length >
          0.55
        ) {
          this.statusMessage = "A borda selecionada não está fechada.";
        } else {
          this.createMeshFromCloseTriangles(
            this.activeObject,
            meshSegmenter.segmentedTriangles,
            0xffff00
          );
          if (this.selectedTooth) {
            this.dentalMap[this.selectedTooth].borderPoints =
              this.$store.getters["editor3D/contourPoints"];
            this.dentalMap[this.selectedTooth].borderTriangleIndices =
              selectedTriangles;
            this.dentalMap[this.selectedTooth].segmentedTriangleIndices = [
              ...meshSegmenter.segmentedTriangles,
            ];
          }
        }
      }
      console.table(this.selectedTooth);
      this.iaActive = "gray";
    },
    extractContour(mesh) {
      const edges = new THREE.EdgesGeometry(mesh.geometry, 90);
      const line = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({ color: 0x0000ff })
      );

      // Extract points from the edges geometry
      const positions = line.geometry.attributes.position.array;
      const points = [];
      for (let i = 0; i < positions.length; i += 3) {
        points.push(
          new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2])
        );
      }

      // Render points as a separate object (optional)
      const pointGeometry = new THREE.BufferGeometry().setFromPoints(points);
      const pointMaterial = new THREE.PointsMaterial({
        color: 0xffffff,
        size: 0.5,
      });
      const pointMesh = new THREE.Points(pointGeometry, pointMaterial);
      return pointMesh;
    },
    DBSCAN(data, eps, MinPts) {
      let clusters = [];
      let visited = new Set();
      let noise = new Set();
      let inCluster = new Set();

      console.table({ data, eps, MinPts });
      console.warn(data.length);

      function regionQuery(point) {
        // Retorna pontos dentro do raio eps
        return data.filter((p) => p.distanceTo(point) < eps);
      }

      function expandCluster(point, neighbors, cluster) {
        visited.add(point);
        inCluster.add(point);
        cluster.push(point);

        let i = 0;
        while (i < neighbors.length) {
          let neighbor = neighbors[i];
          if (!visited.has(neighbor)) {
            visited.add(neighbor);
            const neighborsPrime = regionQuery(neighbor);
            if (neighborsPrime.length >= MinPts) {
              neighbors = neighbors.concat(neighborsPrime);
            }
          }
          if (!inCluster.has(neighbor)) {
            cluster.push(neighbor);
            inCluster.add(neighbor);
          }
          i++;
        }
      }

      data.forEach((point) => {
        if (!visited.has(point)) {
          const neighbors = regionQuery(point);
          if (neighbors.length < MinPts) {
            noise.add(point);
            visited.add(point);
          } else {
            const newCluster = [];
            expandCluster(point, neighbors, newCluster);
            clusters.push(newCluster);
          }
        }
      });

      return clusters;
    },
    dynamicSurfaceProximityMethod() {
      // Assumindo que `originalGeometry` é a sua geometria 3D
      const edgesGeometry = new THREE.EdgesGeometry(
        this.activeObject.geometry,
        90
      );

      //const edgesMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
      //const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);

      // Adiciona edges ao cenário se precisar visualizar
      //this.scene.add(edges);

      // Assumindo que 'edgesGeometry' é uma instância de THREE.EdgesGeometry
      const positionAttribute = edgesGeometry.attributes.position;

      // Use um Map para armazenar os pontos com suas posições como chave
      let edgePointsMap = new Map();

      for (let i = 0; i < positionAttribute.count; i++) {
        let point = new THREE.Vector3();
        point.fromBufferAttribute(positionAttribute, i);

        // Converta o ponto para uma string que será usada como chave única
        const key = `${point.x.toFixed(10)}_${point.y.toFixed(
          10
        )}_${point.z.toFixed(10)}`;
        if (!edgePointsMap.has(key)) {
          edgePointsMap.set(key, point);
        }
      }

      // Agora, 'edgePointsMap' contém apenas pontos únicos. Podemos transformá-lo de volta em um array
      let edgePoints = Array.from(edgePointsMap.values());

      // Verifique se cada ponto tem 2 vizinhos
      edgePoints = edgePoints.filter((point, index, array) => {
        let neighbors = array.filter(
          (otherPoint) =>
            point.distanceTo(otherPoint) < 1 && point !== otherPoint
        );
        return neighbors.length > 2; // Apenas mantenha pontos com exatamente 2 vizinhos
      });

      // Para cada centro de triângulo, calcular a distância mais curta até os pontos de borda
      this.activeObject.geometry.computeBoundingBox();

      const centers = []; // Array para armazenar centros dos triângulos
      const distances = []; // Array para armazenar a transformada de distância de cada centro

      const vertices = this.activeObject.geometry.attributes.position;
      // Vetores reutilizáveis para os vértices dos triângulos
      const v1 = new THREE.Vector3();
      const v2 = new THREE.Vector3();
      const v3 = new THREE.Vector3();
      // Vetor reutilizável para o centro do triângulo
      const center = new THREE.Vector3();

      for (let i = 0; i < vertices.count; i += 3) {
        // Reutiliza os vetores para calcular o centro do triângulo
        v1.fromBufferAttribute(vertices, i);
        v2.fromBufferAttribute(vertices, i + 1);
        v3.fromBufferAttribute(vertices, i + 2);
        center.addVectors(v1, v2).add(v3).divideScalar(3);

        // Calcula a distância mais curta até os pontos de borda
        let minDistance = edgePoints.reduce(
          (min, edgePoint) => Math.min(min, center.distanceTo(edgePoint)),
          Infinity
        );

        centers.push(center.clone()); // Clona o centro antes de adicionar ao array
        distances.push(minDistance);
      }

      // Encontrar a distância máxima para basear a escala de cores
      const maxDistance = Math.max(...distances);

      // Após calcular centers e distances no método dynamicSurfaceProximityMethod
      let pointsWithRatios = centers.map((point, index) => {
        return {
          point: point,
          distanceRatio: distances[index] / maxDistance, // Normalizando
        };
      });

      const highPoints = [];
      pointsWithRatios.forEach((item) => {
        if (item.distanceRatio > 0.25) highPoints.push(item.point);
      });

      // const clustersDB = this.DBSCAN(highPoints, 1, 50);
      // this.displayClusters(clustersDB);
      // console.table(clustersDB)

      let { clusters, centroids } = this.kMedoids(highPoints, 16);
      console.table({ highPoints, clusters, centroids });
      this.displayClustersAndCentroids(clusters, centroids);

      // centers.forEach((center, index) => {
      //   const distance = distances[index];
      //   // Calcula a proporção da distância em relação à máxima
      //   const distanceRatio = distance / maxDistance;

      //   if (distanceRatio > 0.40) {
      //     // Converte essa proporção em uma cor na escala de "frio" para "quente" (azul para vermelho)
      //     // Usaremos uma interpolação simples: azul (menor distância) a vermelho (maior distância)
      //     const color = new THREE.Color().lerpColors(
      //       new THREE.Color(0x0000ff), // Azul
      //       new THREE.Color(0xff0000), // Vermelho
      //       distanceRatio // Proporção que define a mistura das cores
      //     );

      //     // Cria a esfera
      //     const sphereGeometry = new THREE.SphereGeometry(0.2, 4, 4); // Ajuste o tamanho conforme necessário
      //     const sphereMaterial = new THREE.MeshBasicMaterial({ color: color });
      //     const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

      //     // Posiciona a esfera no centro calculado
      //     sphere.position.copy(center);

      //     // Adiciona a esfera à cena
      //     this.activeObject.add(sphere); // Ou scene.add(sphere) se preferir adicionar direto na cena
      //   }
      // });
    },
    displayClusters(clusters, lines = false) {
      const colors = [
        0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0x00ffff, 0xff00ff, 0xffffff,
      ]; // Cores diferentes para cada cluster
      const material = new THREE.LineBasicMaterial({ color: 0xffffff }); // Branco para linhas

      // Buscar ou criar o grupo 'dbscan'
      let dbscanGroup = this.scene.getObjectByName("dbscan");
      if (!dbscanGroup) {
        dbscanGroup = new THREE.Group();
        dbscanGroup.name = "dbscan";
        this.scene.add(dbscanGroup);
      } else {
        // Limpar o grupo existente para evitar sobreposição de objetos
        dbscanGroup.clear();
      }

      clusters.forEach((cluster, index) => {
        const clusterColor = colors[index % colors.length]; // Escolhe uma cor da lista
        cluster.forEach((point) => {
          // Criar uma esfera para cada ponto no cluster
          const sphereGeometry = new THREE.SphereGeometry(0.1, 16, 16); // Tamanho e resolução da esfera menor
          const sphereMaterial = new THREE.MeshBasicMaterial({
            color: clusterColor,
          });
          const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
          sphere.position.copy(point);
          dbscanGroup.add(sphere);

          // Criar linhas, se necessário
          if (lines) {
            cluster.forEach((otherPoint) => {
              if (point !== otherPoint) {
                const points = [];
                points.push(point.clone());
                points.push(otherPoint.clone());

                const geometry = new THREE.BufferGeometry().setFromPoints(
                  points
                );
                const line = new THREE.Line(geometry, material);
                dbscanGroup.add(line);
              }
            });
          }
        });
      });
    },
    displayClustersAndCentroids(clusters, centroids, lines = false) {
      const centroidMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 }); // Vermelho para centroids
      const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff }); // Branco para linhas

      // Buscar ou criar o grupo 'kmeans'
      let kmeansGroup = this.scene.getObjectByName("kmeans");
      if (!kmeansGroup) {
        kmeansGroup = new THREE.Group();
        kmeansGroup.name = "kmeans";
        this.scene.add(kmeansGroup);
      } else {
        // Limpar o grupo existente para evitar sobreposição de objetos
        kmeansGroup.clear();
      }

      centroids.forEach((centroid, index) => {
        // Criar uma esfera para cada centroid
        const sphereGeometry = new THREE.SphereGeometry(1, 16, 16); // Tamanho e resolução da esfera
        const centroidSphere = new THREE.Mesh(sphereGeometry, centroidMaterial);
        centroidSphere.position.copy(centroid);
        kmeansGroup.add(centroidSphere);

        // Criar linhas para cada ponto no cluster correspondente, se necessário
        if (lines) {
          clusters[index].forEach((data) => {
            const points = [];
            points.push(centroid.clone());
            points.push(data.clone());

            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            const line = new THREE.Line(geometry, lineMaterial);
            kmeansGroup.add(line);
          });
        }
      });
    },
    kMeans(points, k) {
      // Inicializa os centroids aleatoriamente
      let centroids = points
        .slice(0)
        .sort(() => 0.5 - Math.random())
        .slice(0, k);
      let oldCentroids = [];
      let iterations = 0;
      let maxIterations = 10000; // Evitar loop infinito
      let clusters;

      while (
        !this.isEqual(oldCentroids, centroids) &&
        iterations < maxIterations
      ) {
        // Agrupa os pontos pelo centroid mais próximo
        clusters = points.reduce((acc, point) => {
          let minDist = Infinity;
          let index = 0;
          centroids.forEach((centroid, i) => {
            let dist = point.distanceTo(centroid);
            if (dist < minDist) {
              minDist = dist;
              index = i;
            }
          });
          if (!acc[index]) {
            acc[index] = [];
          }
          acc[index].push(point);
          return acc;
        }, []);

        // Atualiza os centroids para serem o centro médio de seus clusters
        oldCentroids = centroids;
        centroids = clusters.map((cluster) => {
          let sum = new THREE.Vector3();
          cluster.forEach((p) => sum.add(p));
          sum.divideScalar(cluster.length);
          return sum;
        });
        iterations++;
      }

      return { clusters, centroids };
    },
    kMeansPlusPlus(points, k) {
      let centroids = [];
      // Escolhe o primeiro centroid aleatoriamente dos pontos
      centroids.push(points[Math.floor(Math.random() * points.length)]);

      // Escolhe k-1 centroids
      for (let i = 1; i < k; i++) {
        // Calcula a distância mínima de cada ponto para todos os centroids escolhidos
        let distances = points.map((p) =>
          Math.min(...centroids.map((c) => p.distanceTo(c)))
        );

        // Escolhe um novo centroid com probabilidade proporcional à distância ao quadrado
        let totalDistance = distances.reduce(
          (sum, dist) => sum + dist * dist,
          0
        );
        let random = Math.random() * totalDistance;
        for (let j = 0, sum = 0; j < distances.length; j++) {
          sum += distances[j] * distances[j];
          if (sum >= random) {
            centroids.push(points[j]);
            break;
          }
        }
      }

      return centroids;
    },
    kMedoids(points, k) {
      // Inicializa os centroids com K-means++
      let centroids = this.kMeansPlusPlus(points, k);
      let oldCentroids = [];
      let iterations = 0;
      let maxIterations = 1000; // Evitar loop infinito
      let clusters;

      while (
        !this.isEqual(oldCentroids, centroids) &&
        iterations < maxIterations
      ) {
        // Agrupa os pontos pelo centroid mais próximo
        clusters = points.reduce((acc, point) => {
          let minDist = Infinity;
          let index = 0;
          centroids.forEach((centroid, i) => {
            let dist = point.distanceTo(centroid);
            if (dist < minDist) {
              minDist = dist;
              index = i;
            }
          });
          if (!acc[index]) {
            acc[index] = [];
          }
          acc[index].push(point);
          return acc;
        }, []);

        // Atualiza os centroids para serem o ponto mais próximo do centro médio de seus clusters
        oldCentroids = centroids;
        centroids = clusters.map((cluster) => {
          let centroid = cluster[0]; // Pega o primeiro ponto do cluster como inicial
          let center = new THREE.Vector3();
          cluster.forEach((p) => center.add(p));
          center.divideScalar(cluster.length);

          let minDist = Infinity;
          cluster.forEach((p) => {
            let dist = p.distanceTo(center);
            if (dist < minDist) {
              minDist = dist;
              centroid = p;
            }
          });
          return centroid;
        });
        iterations++;
      }

      return { clusters, centroids };
    },
    isEqual(oldCentroids, centroids) {
      if (oldCentroids.length !== centroids.length) return false;
      for (let i = 0; i < centroids.length; i++) {
        if (!oldCentroids[i].equals(centroids[i])) {
          return false;
        }
      }
      return true;
    },
    findDenseRegions(radius) {
      const geometry = this.activeObject.geometry;
      const vertices = geometry.attributes.position.array;
      const step = radius;
      const densityMap = new Map();

      geometry.computeBoundingBox();
      const bbox = geometry.boundingBox;

      const ignoreTopBottomPercentage = 0.1;
      const heightAdjustment =
        (bbox.max.y - bbox.min.y) * ignoreTopBottomPercentage;
      const adjustedMinY = bbox.min.y + heightAdjustment;
      const adjustedMaxY = bbox.max.y - heightAdjustment;

      const vertexA = new THREE.Vector3();
      const vertexB = new THREE.Vector3();
      const vertexC = new THREE.Vector3();
      const center = new THREE.Vector3();

      for (let x = bbox.min.x; x <= bbox.max.x; x += step) {
        for (let y = adjustedMinY; y <= adjustedMaxY; y += step) {
          for (let z = bbox.min.z; z <= bbox.max.z; z += step) {
            center.set(x, y, z);
            let count = 0; // Contagem de vértices dentro do raio
            let areaSum = 0; // Soma da área dos triângulos dentro do raio

            for (let i = 0; i < vertices.length; i += 9) {
              vertexA.set(vertices[i], vertices[i + 1], vertices[i + 2]);
              vertexB.set(vertices[i + 3], vertices[i + 4], vertices[i + 5]);
              vertexC.set(vertices[i + 6], vertices[i + 7], vertices[i + 8]);

              // Verifica se algum dos vértices do triângulo está dentro do raio especificado
              const distanceA = center.distanceTo(vertexA);
              const distanceB = center.distanceTo(vertexB);
              const distanceC = center.distanceTo(vertexC);
              if (
                distanceA <= radius ||
                distanceB <= radius ||
                distanceC <= radius
              ) {
                count +=
                  (distanceA <= radius) +
                  (distanceB <= radius) +
                  (distanceC <= radius);
                const area = this.triangleArea(vertexA, vertexB, vertexC);
                areaSum += area; // Soma a área deste triângulo se algum vértice está dentro do raio
              }
            }

            if (count > 0 || areaSum > 0) {
              // Armazena tanto a contagem de vértices quanto a soma das áreas
              densityMap.set(JSON.stringify({ x, y, z }), { count, areaSum });
            }
          }
        }
      }

      console.warn(`Total de pontos rastreados ${densityMap.size}`);

      // Extrai os valores acumulados para contagem de vértices e soma das áreas
      const totals = Array.from(densityMap.values()).reduce(
        (acc, value) => {
          acc.totalVerticesCount += value.count;
          acc.totalAreaSum += value.areaSum;
          return acc;
        },
        { totalVerticesCount: 0, totalAreaSum: 0 }
      );

      // Calcula a densidade média de vértices e a área média
      const averageVertexDensity = totals.totalVerticesCount / densityMap.size;
      const averageArea = totals.totalAreaSum / densityMap.size;

      console.log(`Densidade média de vértices: ${averageVertexDensity}`);
      console.log(`Área média dos triângulos: ${averageArea}`);

      // Define o múltiplo da densidade média de vértices e da área média para usar como limiares
      const vertexDensityThresholdMultiplier = 1.45;
      const areaThresholdMultiplier = 1.75; // Ajustável conforme a necessidade

      // Calcula os limiares dinâmicos
      const dynamicVertexThreshold =
        averageVertexDensity * vertexDensityThresholdMultiplier;
      const dynamicAreaThreshold = averageArea * areaThresholdMultiplier;

      // Filtra, ordena os centros com base em critérios combinados e seleciona os top N (ex: 32) primeiros
      const topCenters = Array.from(densityMap)
        // eslint-disable-next-line no-unused-vars
        .filter(
          ([, value]) =>
            value.count > dynamicVertexThreshold &&
            value.areaSum > dynamicAreaThreshold
        ) // Aplica ambos os limiares
        .sort((a, b) => {
          // Critério de ordenação combinado pode ser ajustado conforme necessário
          const densityDiff = b[1].count - a[1].count;
          if (densityDiff !== 0) return densityDiff; // Prioriza densidade de vértices se diferir
          return b[1].areaSum - a[1].areaSum; // Caso contrário, usa a soma das áreas como desempate
        });

      // Plota esferas nos centros de maior densidade
      topCenters.forEach((center) => {
        const position = JSON.parse(center[0]);
        const sphereGeometry = new THREE.SphereGeometry(radius, 8, 8); // Raio reduzido para visualização
        const sphereMaterial = new THREE.MeshBasicMaterial({
          color: 0xffff00,
          transparent: true,
          opacity: 0.25,
        });
        const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        sphere.position.set(position.x, position.y, position.z);
        this.activeObject.add(sphere); // Adiciona a esfera ao mesh (ou scene.add(sphere) se preferir adicionar à cena)
      });

      console.log("Centros de maior densidade:", topCenters);
    },
    triangleArea(vertexA, vertexB, vertexC) {
      // Calcula a área do triângulo usando a fórmula de Heron adaptada para vetores
      const sideAB = vertexB.clone().sub(vertexA).length();
      const sideBC = vertexC.clone().sub(vertexB).length();
      const sideCA = vertexA.clone().sub(vertexC).length();
      const s = (sideAB + sideBC + sideCA) / 2;
      return Math.sqrt(s * (s - sideAB) * (s - sideBC) * (s - sideCA));
    },
    centerGeometry(geometry, mesh) {
      geometry.computeBoundingBox();
      const offset = geometry.boundingBox
        .getCenter(new THREE.Vector3())
        .negate();

      // Calcula a posição original do mesh
      const originalPosition = mesh.position.clone();

      // Translada a geometria para o centro de massa estar na origem
      geometry.translate(offset.x, offset.y, offset.z);

      // Atualiza a posição do mesh para compensar a translação da geometria
      mesh.position.sub(offset);

      // Move o mesh de volta para a posição original
      mesh.position.add(originalPosition);
    },
    extractGeometry(activeObject, indices) {
      const positions = activeObject.geometry.attributes.position.array;
      const selectedVertices = [];

      indices.forEach((index) => {
        const baseIdx = index * 9;
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
      });

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(selectedVertices, 3)
      );
      geometry.computeVertexNormals();
      this.calculateVertexNormals(geometry);
      return geometry;
    },
    calculateCenterOfMass(borderPoints) {
      const sum = borderPoints.reduce(
        (acc, point) => {
          acc.x += point.x;
          acc.y += point.y;
          acc.z += point.z;
          return acc;
        },
        { x: 0, y: 0, z: 0 }
      );

      const count = borderPoints.length;
      return new THREE.Vector3(sum.x / count, sum.y / count, sum.z / count);
    },
    createCircularContour(center, radius, segments) {
      const points = [];
      for (let i = 0; i < segments; i++) {
        const angle = (i / segments) * Math.PI * 2;
        points.push(
          new THREE.Vector3(
            center.x + radius * Math.cos(angle),
            center.y, // Trava o eixo Y
            center.z + radius * Math.sin(angle)
          )
        );
      }
      return points;
    },
    mergeGeometries(geometries) {
      // Arrays para armazenar os dados combinados
      let combinedVertices = [];
      let combinedIndices = [];
      let combinedNormals = [];

      // Offset necessário para ajustar os índices para a nova lista de vértices
      let vertexOffset = 0;

      // Função auxiliar para concatenar arrays de forma segura
      function concatenateArray(targetArray, sourceArray) {
        const chunkSize = 10000; // Tamanho dos pedaços para evitar estourar a pilha
        for (let i = 0; i < sourceArray.length; i += chunkSize) {
          targetArray.push(...sourceArray.slice(i, i + chunkSize));
        }
      }

      geometries.forEach((geometry) => {
        // Assumindo que a geometria já tenha um atributo de posição
        const positions = geometry.getAttribute("position").array;
        const normals = geometry.getAttribute("normal").array;

        // Adiciona os vértices ao array combinado
        concatenateArray(combinedVertices, positions);

        // Adiciona os normais ao array combinado
        concatenateArray(combinedNormals, normals);

        // Se a geometria tiver um índice, ajusta e adiciona ao array combinado
        if (geometry.index) {
          const indices = geometry.index.array;
          combinedIndices.push(...indices.map((index) => index + vertexOffset));
        } else {
          // Se não houver índice, gera um novo baseado na posição dos vértices
          let numVerts = positions.length / 3;
          for (let i = 0; i < numVerts; i++) {
            combinedIndices.push(i + vertexOffset);
          }
        }

        // Atualiza o offset para a próxima geometria
        vertexOffset += positions.length / 3;
      });

      // Cria uma nova BufferGeometry
      const combinedGeometry = new THREE.BufferGeometry();

      // Adiciona os vértices combinados
      combinedGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(combinedVertices, 3)
      );

      // Adiciona os normais combinados
      combinedGeometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(combinedNormals, 3)
      );

      // Adiciona os índices combinados, se necessário
      if (combinedIndices.length > 0) {
        combinedGeometry.setIndex(combinedIndices);
      }

      combinedGeometry.computeBoundingBox();
      combinedGeometry.computeBoundingSphere();

      return combinedGeometry;
    },
    mergeGeometries_(geometries) {
      // Arrays para armazenar os dados combinados
      let combinedVertices = [];
      let combinedIndices = [];
      let combinedNormals = [];

      // Offset necessário para ajustar os índices para a nova lista de vértices
      let vertexOffset = 0;

      geometries.forEach((geometry) => {
        // Assumindo que a geometria já tenha um atributo de posição
        const positions = geometry.getAttribute("position").array;

        // Adiciona os vértices ao array combinado
        combinedVertices.push(...positions);

        const normals = geometry.getAttribute("normal").array;

        // Adiciona os vértices ao array combinado
        combinedNormals.push(...normals);

        // Se a geometria tiver um índice, ajusta e adiciona ao array combinado
        if (geometry.index) {
          const indices = geometry.index.array;
          combinedIndices.push(...indices.map((index) => index + vertexOffset));
        } else {
          // Se não houver índice, gera um novo baseado na posição dos vértices
          let numVerts = positions.length / 3;
          for (let i = 0; i < numVerts; i++) {
            combinedIndices.push(i + vertexOffset);
          }
        }

        // Atualiza o offset para a próxima geometria
        vertexOffset += positions.length / 3;
      });

      // Cria uma nova BufferGeometry
      const combinedGeometry = new THREE.BufferGeometry();

      // Adiciona os vértices combinados
      combinedGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(combinedVertices, 3)
      );

      // Adiciona os normais combinadas
      combinedGeometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(combinedNormals, 3)
      );

      // Adiciona os índices combinados, se necessário
      if (combinedIndices.length > 0) {
        combinedGeometry.setIndex(combinedIndices);
      }

      combinedGeometry.computeBoundingBox();
      combinedGeometry.computeBoundingSphere();

      return combinedGeometry;
    },

    extractAndCloseGeometry(toothData) {
      // Assume que this.activeObject é acessível e contém a malha dentária completa
      const positions = this.activeObject.geometry.attributes.position.array;
      const selectedVertices = [];

      // Extrai os vértices dos dentes usando os índices dos triângulos segmentados
      toothData.segmentedTriangleIndices.forEach((index) => {
        const baseIdx = index * 9;
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
      });

      // Extrai os vértices dos dentes usando os índices dos triângulos segmentados
      toothData.borderTriangleIndices.forEach((index) => {
        const baseIdx = index * 9;
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
      });

      // Cria a geometria do dente
      const toothGeometry = new THREE.BufferGeometry();
      toothGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(selectedVertices, 3)
      );

      const toothEdgesGeometry = new THREE.EdgesGeometry(toothGeometry, 90);
      const edgeVertices = toothEdgesGeometry.attributes.position.array;
      let edgeVerticesArray = [];
      for (let i = 0; i < edgeVertices.length; i += 3) {
        edgeVerticesArray.push(
          new THREE.Vector3(
            edgeVertices[i],
            edgeVertices[i + 1],
            edgeVertices[i + 2]
          )
        );
      }

      //const uniqueVertices = this.uniqueVerticesFunction(edgeVerticesArray);

      // Calcula o centro de massa do dente usando os pontos da borda
      const toothCenter = this.calculateCenterOfMass(toothData.borderPoints);
      //const finalSeamGeometry = this.create3DSeam(uniqueVertices, toothData.borderPoints);

      const isUpperArc =
        this.activeObject.userData.modelType === "maxillary" ? true : false;

      toothCenter.y += isUpperArc ? 4 : -4;
      toothCenter.z -= 2;

      // Gera o contorno da raiz
      const rootContour = this.createCircularContour(
        toothCenter,
        0.5,
        toothData.borderPoints.length
      );

      // Costura a borda do dente com o contorno da raiz
      // eslint-disable-next-line no-unused-vars
      const seamGeometry = this.create3DSeam(
        toothData.borderPoints,
        rootContour
      );

      // Adiciona a esfera de fechamento no centro de massa do dente
      const closingSphereGeometry = new THREE.SphereGeometry(0.5, 16, 16);
      closingSphereGeometry.translate(
        toothCenter.x,
        toothCenter.y,
        toothCenter.z
      );

      const combinedGeometry = this.mergeGeometries([toothGeometry]);

      // Agora calcule as normais dos vértices
      combinedGeometry.computeVertexNormals();
      this.calculateVertexNormals(combinedGeometry);

      // Verifica se não há NaN nas normais
      const normals = combinedGeometry.getAttribute("normal");
      for (let i = 0; i < normals.count; i++) {
        if (
          isNaN(normals.getX(i)) ||
          isNaN(normals.getY(i)) ||
          isNaN(normals.getZ(i))
        ) {
          normals.setXYZ(i, 0, 0, 1);
        }
      }

      // Depois de verificar, atualize o atributo normal
      normals.needsUpdate = true;
      return combinedGeometry;
    },
    newExtractAndCloseGeometry(toothData, toothNumber, activeObject) {
      if (toothData.segmentedTriangleIndices.length === 0) return;
      // Assume que this.activeObject é acessível e contém a malha dentária completa
      const positions = activeObject.geometry.attributes.position.array;
      const normals = activeObject.geometry.attributes.normal.array;
      const selectedVertices = [];
      const selectedNormals = [];

      // Extrai os vértices dos dentes usando os índices dos triângulos segmentados
      toothData.segmentedTriangleIndices.forEach((index) => {
        const baseIdx = index * 9;
        for (let i = 0; i < 9; i++) {
          selectedVertices.push(positions[baseIdx + i]);
        }
        for (let i = 0; i < 3; i++) {
          selectedNormals.push(normals[index * 3 + i]);
        }
      });

      // Cria a geometria do dente
      const toothGeometry = new THREE.BufferGeometry();
      toothGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(selectedVertices, 3)
      );

      toothGeometry.computeVertexNormals();
      this.calculateVertexNormals(toothGeometry);

      console.log("Start 3D reconstruiction");
      const combinedGeometry = this.tooth3DReconstruction(
        toothGeometry,
        toothData,
        toothNumber
      );
      console.log("End 3D reconstruiction");
      // combinedGeometry.computeVertexNormals();
      // this.calculateVertexNormals(combinedGeometry);

      return combinedGeometry;
    },
    createGumGeometry(activeObject, dentalMap) {
      const positions = activeObject.geometry.attributes.position.array;
      const originalTriangleCount = positions.length / 9;

      // Criar um Set para armazenar todos os índices de triângulos dos dentes
      const toothTriangles = new Set();
      Object.values(dentalMap).forEach(
        ({ segmentedTriangleIndices, borderTriangleIndices }) => {
          segmentedTriangleIndices.forEach((index) =>
            toothTriangles.add(index)
          );
          borderTriangleIndices.forEach((index) => toothTriangles.add(index));
        }
      );

      // Criar um array para armazenar os vértices da gengiva
      const gumVertices = new Float32Array(positions.length);
      let gumIndex = 0;

      // Preencher o array de vértices da gengiva, excluindo os vértices dos dentes
      for (let i = 0; i < originalTriangleCount; i++) {
        if (!toothTriangles.has(i)) {
          for (let j = 0; j < 9; j++) {
            gumVertices[gumIndex++] = positions[i * 9 + j];
          }
        }
      }

      const originalVertices = [];
      for (
        let i = 0;
        i < activeObject.geometry.attributes.position.count;
        i++
      ) {
        const vertex = new THREE.Vector3();
        vertex.fromBufferAttribute(
          activeObject.geometry.attributes.position,
          i
        );
        originalVertices.push(vertex);
      }

      // Criar uma nova geometria com os vértices da gengiva
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(gumVertices.subarray(0, gumIndex), 3)
      );

      geometry.computeVertexNormals();
      this.calculateVertexNormals(geometry);

      return { geometry: geometry, originalVertices: originalVertices };
    },

    createGumGeometry__(activeObject, dentalMap) {
      // Primeiro, criar um array grande o suficiente para conter todos os vértices, mas sem os dentes
      const positions = activeObject.geometry.attributes.position.array;
      const gumVertices = new Float32Array(positions.length);
      let gumIndex = 0;

      console.table(dentalMap);
      // Preencher o array de vértices da gengiva, excluindo os vértices dos dentes
      for (let i = 0, l = positions.length / 9; i < l; i++) {
        if (!this.isToothTriangle(i, dentalMap)) {
          for (let j = 0; j < 9; j++) {
            gumVertices[gumIndex++] = positions[i * 9 + j];
          }
        }
      }

      const originalVertices = [];

      for (
        let i = 0;
        i < activeObject.geometry.attributes.position.count;
        i++
      ) {
        const vertex = new THREE.Vector3();
        vertex.fromBufferAttribute(
          activeObject.geometry.attributes.position,
          i
        );
        originalVertices.push(vertex);
      }

      // Criar uma nova geometria com os vértices da gengiva
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(gumVertices.subarray(0, gumIndex), 3)
      );

      // Aplicar a suavização laplaciana
      //this.applyLaplacianSmoothing(geometry);

      geometry.computeVertexNormals();
      this.calculateVertexNormals(geometry);

      return { geometry: geometry, originalVertices: originalVertices };
    },

    isToothTriangle(triangleIndex, dentalMap) {
      // Verifica se o índice do triângulo pertence a algum dente
      for (const toothNumber of Object.keys(dentalMap)) {
        if (
          dentalMap[toothNumber].segmentedTriangleIndices.includes(
            triangleIndex
          )
        ) {
          return true;
        }
        if (
          dentalMap[toothNumber].borderTriangleIndices.includes(triangleIndex)
        ) {
          return true;
        }
      }
      return false;
    },

    createGumGeometry_(activeObject, dentalMap) {
      const gumPoints = this.extractGumPoints(activeObject, dentalMap);
      const gumGeometry = this.triangulateGum(gumPoints);
      //this.applyLaplacianSmoothing(gumGeometry);
      return { geometry: gumGeometry, originalVertices: gumPoints };
    },

    applyLaplacianSmoothing(geometry, lambda = 0.1, iterations = 10) {
      const vertices = geometry.attributes.position.array;
      const neighborIndices = this.computeNeighborIndices(geometry);

      for (let iter = 0; iter < iterations; iter++) {
        const smoothedVertices = new Float32Array(vertices.length);

        for (let i = 0; i < vertices.length / 3; i++) {
          const neighbors = neighborIndices[i];
          let sumX = 0,
            sumY = 0,
            sumZ = 0;

          neighbors.forEach((neighbor) => {
            sumX += vertices[neighbor * 3];
            sumY += vertices[neighbor * 3 + 1];
            sumZ += vertices[neighbor * 3 + 2];
          });

          smoothedVertices[i * 3] =
            vertices[i * 3] +
            lambda * (sumX / neighbors.length - vertices[i * 3]);
          smoothedVertices[i * 3 + 1] =
            vertices[i * 3 + 1] +
            lambda * (sumY / neighbors.length - vertices[i * 3 + 1]);
          smoothedVertices[i * 3 + 2] =
            vertices[i * 3 + 2] +
            lambda * (sumZ / neighbors.length - vertices[i * 3 + 2]);
        }

        // Atualiza os vértices originais com os vértices suavizados
        for (let i = 0; i < vertices.length; i++) {
          vertices[i] = smoothedVertices[i];
        }
      }

      geometry.attributes.position.needsUpdate = true;
      geometry.computeVertexNormals();
    },

    computeNeighborIndices(geometry) {
      const vertices = geometry.attributes.position.array;
      const neighborMap = new Map();

      for (let i = 0; i < vertices.length / 3; i++) {
        neighborMap.set(i, new Set());
      }

      // Percorre os triângulos para construir o mapa de vizinhos
      for (let i = 0; i < vertices.length; i += 9) {
        const v1 = i / 3;
        const v2 = v1 + 1;
        const v3 = v1 + 2;

        neighborMap.get(v1).add(v2).add(v3);
        neighborMap.get(v2).add(v1).add(v3);
        neighborMap.get(v3).add(v1).add(v2);
      }

      const neighborIndices = [];
      neighborMap.forEach((neighbors, vertexIndex) => {
        neighborIndices[vertexIndex] = Array.from(neighbors);
      });

      return neighborIndices;
    },
    findMeshByModelId(modelId) {
      let foundMesh = null;

      // Traverse pela scene para encontrar o mesh com o modelId especificado
      this.scene.traverse((object) => {
        // Verifica se o objeto é um Mesh e se o modelId corresponde ao valor fornecido
        if (object.isMesh && object.userData.modelId == modelId) {
          foundMesh = object; // Armazena o mesh encontrado
        }
      });
      return foundMesh;
    },
    createDentalMeshGroup(dentalMap, modelId = 0) {
      const activeObject =
        modelId === 0 ? this.activeObject : this.findMeshByModelId(modelId);

      // Criar Mesh Group
      const dentalMeshGroup = new THREE.Group();

      // Adicionar Dentes ao Grupo
      Object.keys(dentalMap).forEach((toothNumber) => {
        const toothData = dentalMap[toothNumber];
        //const toothGeometry = this.extractGeometry(activeObject, toothData.segmentedTriangleIndices);
        console.group(`Start segmentation`);
        console.log(`%cTooth ${toothNumber}`, "font-size:32px;color:green;");
        const toothGeometry = this.newExtractAndCloseGeometry(
          toothData,
          toothNumber,
          activeObject
        );
        if (toothGeometry) {
          toothGeometry.computeBoundingBox();
          const toothMesh = new THREE.Mesh(
            toothGeometry,
            new THREE.MeshPhongMaterial({
              color: 0xfffffe,
              specular: 0xffffff,
              emissive: 0x000000,
              shininess: 0,
              side: THREE.DoubleSide,
              reflectivity: 0,
            })
              // new THREE.MeshBasicMaterial({
              //   color: 0xffff00,
              //   side: THREE.DoubleSide,
              //   wireframe: true,
              // })
          );

          toothMesh.userData = {
            objectType: "tooth",
            toothNumber: toothNumber,
            category: "tooth",
          };

          // Reposiciona cada dente para ter o centro de massa na origem
          this.centerGeometry(toothGeometry, toothMesh);

          dentalMeshGroup.add(toothMesh);
          console.groupEnd();
        }
      });

      // Criar a Geometria da Gengiva
      console.group(`Start Gum Segmentation`);
      const gumObject = this.createGumGeometry(activeObject, dentalMap);
      const gumGeometry = gumObject.geometry;
      console.log("End Gum Segmentation");
      console.groupEnd();

      const gumMesh = new THREE.Mesh(
        gumGeometry,
        new THREE.MeshPhongMaterial({
          color: 0xffd0db, // 0xffc0cb
          specular: 0x333366,
          emissive: 0x000000,
          shininess: 0,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 1,
          reflectivity: 0,
        })
      );
      gumMesh.userData = {
        objectType: "gums",
        category: "gums",
        originalVertices: gumObject.originalVertices,
      };
      dentalMeshGroup.add(gumMesh);
      // this.scene.add(gumMesh)

      // const { mass, stiffness, damping } = { mass: 1, stiffness: 10, damping: 0.8 };
      //           const softBody = new SoftBody(this.scene, gumMesh, mass, stiffness, damping);

      //           // Adiciona ao simulador
      //           if (this.simulator) {
      //               this.simulator.addSoftBody(softBody);
      //           }

      //           console.log(`SoftBody criado: ${name}, ModelID: ${modelId}`);

      dentalMeshGroup.userData = activeObject.userData;
      activeObject.visible = false;

      this.setMeshCategory(dentalMeshGroup, "segmented");
      this.scene.add(dentalMeshGroup);
      this.$store.dispatch("editor3D/updateScene", this.scene);
      this.sceneUpdate = new Date();
      this.$store.dispatch("editor3D/updateDate", this.sceneUpdate);
    },

    findLargestDistanceWithFixedHeight(points, arcType) {
      if (!["upper", "lower"].includes(arcType)) {
        console.error('O tipo de arcada deve ser "upper" ou "lower".');
        return;
      }

      let referenceHeight;
      if (arcType === "upper") {
        referenceHeight = Math.min(...points.map((p) => p.y));
      } else {
        referenceHeight = Math.max(...points.map((p) => p.y));
      }

      // A função de distância considera apenas as coordenadas x e z
      function distance(point1, point2) {
        return Math.sqrt(
          (point1.x - point2.x) ** 2 + (point1.z - point2.z) ** 2
        );
      }

      let largestDistance = 0;
      let point1Result = null;
      let point2Result = null;
      for (let i = 0; i < points.length; i++) {
        for (let j = i + 1; j < points.length; j++) {
          const currentDistance = distance(points[i], points[j]);
          if (currentDistance > largestDistance) {
            largestDistance = currentDistance;
            // Clona os pontos e ajusta suas alturas para referenceHeight antes de salvar
            point1Result = { ...points[i], y: referenceHeight };
            point2Result = { ...points[j], y: referenceHeight };
          }
        }
      }

      return { largestDistance, point1: point1Result, point2: point2Result };
    },

    extractGumPoints(activeObject, dentalMap) {
      const positions = activeObject.geometry.attributes.position.array;
      const gumVertices = new Float32Array(positions.length);
      let gumIndex = 0;

      // Preencher o array de vértices da gengiva, excluindo os vértices dos dentes
      for (let i = 0, l = positions.length / 9; i < l; i++) {
        if (!this.isToothTriangle(i, dentalMap)) {
          for (let j = 0; j < 9; j++) {
            gumVertices[gumIndex++] = positions[i * 9 + j];
          }
        }
      }

      const gumPoints = [];

      for (
        let i = 0;
        i < activeObject.geometry.attributes.position.count;
        i++
      ) {
        const vertex = new THREE.Vector3();
        vertex.fromBufferAttribute(
          activeObject.geometry.attributes.position,
          i
        );
        gumPoints.push([vertex.x, vertex.y, vertex.z]);
      }

      return gumPoints;
    },

    isToothVertex(vertexIndex, dentalMap) {
      for (const toothNumber of Object.keys(dentalMap)) {
        if (
          dentalMap[toothNumber].segmentedVertexIndices.includes(vertexIndex)
        ) {
          return true;
        }
        if (dentalMap[toothNumber].borderVertexIndices.includes(vertexIndex)) {
          return true;
        }
      }
      return false;
    },
    triangulateGum(gumPoints) {
      const delaunay = Delaunator.from(gumPoints);
      const triangles = delaunay.triangles;

      console.table({ gumPoints, delaunay, triangles });

      const geometry = new THREE.BufferGeometry();
      const vertices = new Float32Array(triangles.length * 3);

      for (let i = 0; i < triangles.length; i++) {
        const point = gumPoints[triangles[i]];
        vertices[i * 3] = point[0];
        vertices[i * 3 + 1] = point[1];
        vertices[i * 3 + 2] = point[2];
      }

      geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
      geometry.computeVertexNormals();

      return geometry;
    },
  },
};

// eslint-disable-next-line no-unused-vars
class MeshSegmenter {
  constructor(mesh, borderTriangleIndices, isUpperArc, searchRadius) {
    this.startTime = performance.now();
    this.mesh = mesh;
    this.borderTriangleIndices = borderTriangleIndices;
    this.isUpperArc = isUpperArc;
    this.searchRadius = searchRadius;
    this.segmentedTriangles = new Set();
    this.visited = new Set();
    this.firstTriangle = null;

    this.vertexMap = new Map();
    this.adjacencyMap = new Map();

    this.centroid = this.calculateCentroidFromBorderTriangles();
    console.log(
      `Centroid calculation time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );
    this.candidateTriangles = this.filterTrianglesWithinRadius();
    console.log(
      `Filtering triangles time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    this.initializeVertexMap();
    console.log(
      `Initialize vertex map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );

    this.buildAdjacencyMap();
    console.log(
      `Building adjacency map time: ${(
        performance.now() - this.startTime
      ).toFixed(2)} ms`
    );
  }

  calculateTriangleCenter(triangleIndex) {
    const positionAttribute = this.mesh.geometry.attributes.position;
    const v1 = new THREE.Vector3().fromBufferAttribute(
      positionAttribute,
      triangleIndex * 3 + 0
    );
    const v2 = new THREE.Vector3().fromBufferAttribute(
      positionAttribute,
      triangleIndex * 3 + 1
    );
    const v3 = new THREE.Vector3().fromBufferAttribute(
      positionAttribute,
      triangleIndex * 3 + 2
    );
    return new THREE.Vector3().addVectors(v1, v2).add(v3).divideScalar(3);
  }

  calculateCentroidFromBorderTriangles() {
    const centroid = new THREE.Vector3();
    let count = 0;

    this.borderTriangleIndices.forEach((index) => {
      const center = this.calculateTriangleCenter(index);
      centroid.add(center);
      count++;
    });

    if (count > 0) centroid.divideScalar(count);
    return centroid;
  }

  filterTrianglesWithinRadius() {
    const candidateTriangles = [];
    const positions = this.mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9; // Cada triângulo tem 3 vértices, cada vértice tem 3 coordenadas (x, y, z)

    for (let i = 0; i < totalTriangles; i++) {
      // Calcula o índice base no buffer para o triângulo atual
      const baseIndex = i * 9;
      // Extrai os vértices do triângulo
      const vertices = [
        new THREE.Vector3(
          positions[baseIndex],
          positions[baseIndex + 1],
          positions[baseIndex + 2]
        ),
        new THREE.Vector3(
          positions[baseIndex + 3],
          positions[baseIndex + 4],
          positions[baseIndex + 5]
        ),
        new THREE.Vector3(
          positions[baseIndex + 6],
          positions[baseIndex + 7],
          positions[baseIndex + 8]
        ),
      ];
      // Calcula o centro usando os vértices
      const center = new THREE.Vector3()
        .add(vertices[0])
        .add(vertices[1])
        .add(vertices[2])
        .divideScalar(3);

      if (center.distanceTo(this.centroid) <= this.searchRadius) {
        candidateTriangles.push({ index: i, center, vertices });
      }
    }

    return candidateTriangles;
  }

  initializeVertexMap() {
    const positions = this.mesh.geometry.attributes.position.array;
    const totalTriangles = positions.length / 9; // Cada triângulo tem 3 vértices

    for (let i = 0; i < totalTriangles; i++) {
      const baseIndex = i * 9;
      for (let j = 0; j < 3; j++) {
        // Loop para cada vértice do triângulo
        const x = positions[baseIndex + j * 3];
        const y = positions[baseIndex + j * 3 + 1];
        const z = positions[baseIndex + j * 3 + 2];
        const key = `${x.toFixed(15)}-${y.toFixed(15)}-${z.toFixed(15)}`; // Reduzindo a precisão para evitar erros de ponto flutuante

        if (!this.vertexMap.has(key)) {
          this.vertexMap.set(key, []);
        }
        this.vertexMap.get(key).push(i);
      }
    }
  }

  buildAdjacencyMap() {
    for (let triangleIndex of this.vertexMap.values()) {
      for (let i = 0; i < triangleIndex.length; i++) {
        for (let j = i + 1; j < triangleIndex.length; j++) {
          const index1 = triangleIndex[i];
          const index2 = triangleIndex[j];

          if (!this.adjacencyMap.has(index1)) {
            this.adjacencyMap.set(index1, new Set());
          }
          this.adjacencyMap.get(index1).add(index2);

          if (!this.adjacencyMap.has(index2)) {
            this.adjacencyMap.set(index2, new Set());
          }
          this.adjacencyMap.get(index2).add(index1);
        }
      }
    }
  }

  buildAdjacencyMap_() {
    const adjacencyMap = new Map();

    // Para cada triângulo candidato, encontre seus vizinhos (isso requer uma lógica específica baseada na sua malha)
    this.candidateTriangles.forEach((candidate) => {
      const neighbors = this.findNeighbors(candidate.index);
      adjacencyMap.set(candidate.index, neighbors);
    });

    return adjacencyMap;
  }

  findNeighbors(triangleIndex) {
    const neighbors = [];
    // Encontra o triângulo candidato correspondente ao índice fornecido
    const candidate = this.candidateTriangles.find(
      (c) => c.index === triangleIndex
    );
    if (!candidate) {
      return neighbors; // Retorna vazio se o triângulo não for encontrado nos candidatos
    }

    // Itera sobre os triângulos candidatos para encontrar vizinhos
    this.candidateTriangles.forEach((otherCandidate) => {
      // Ignora o próprio triângulo e triângulos de borda
      if (
        otherCandidate.index === triangleIndex ||
        this.borderTriangleIndices.includes(otherCandidate.index)
      ) {
        return;
      }

      // Verifica se os triângulos compartilham um vértice
      const sharedVertex = candidate.vertices.some((vertex) =>
        otherCandidate.vertices.some(
          (otherVertex) =>
            vertex.x === otherVertex.x &&
            vertex.y === otherVertex.y &&
            vertex.z === otherVertex.z
        )
      );

      // Se compartilham um vértice, considera-se vizinhos
      if (sharedVertex) {
        neighbors.push(otherCandidate.index);
      }
    });

    return neighbors;
  }

  findTriangleIndexByPosition(x, y, z) {
    const point = new THREE.Vector3(x, y, z);
    let closestTriangleIndex = -1;
    let closestDistance = Infinity;

    this.candidateTriangles.forEach((candidate) => {
      const distance = point.distanceTo(candidate.center);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestTriangleIndex = candidate.index;
      }
    });

    return closestTriangleIndex;
  }

  findEustaquio() {
    const direction = this.isUpperArc
      ? new THREE.Vector3(0, -10, 0)
      : new THREE.Vector3(0, 10, 0);
    const raycaster = new THREE.Raycaster(this.centroid, direction);
    const intersects = raycaster.intersectObject(this.mesh);

    if (intersects.length > 0) {
      const eustaquioPoint = intersects[0].point;
      return this.findTriangleIndexByPosition(
        eustaquioPoint.x,
        eustaquioPoint.y,
        eustaquioPoint.z
      );
    }
    return null;
  }

  segment() {
    let eustaquio = this.findEustaquio();
    this.firstTriangle = eustaquio;
    console.log(
      `Finding Eustaquio time: ${(performance.now() - this.startTime).toFixed(
        2
      )} ms`
    );

    if (eustaquio !== null) {
      this.expandFromEustaquio(eustaquio);
      console.log(
        `Segmentation time: ${(performance.now() - this.startTime).toFixed(
          2
        )} ms`
      );
    }
  }

  expandFromEustaquio(eustaquioIndex) {
    let queue = [eustaquioIndex];
    let candidatesLength = this.candidateTriangles.length;
    let segmentedTrianglesSize = this.segmentedTriangles.size;

    while (queue.length > 0) {
      const current = queue.shift();

      if (this.visited.has(current)) continue;
      this.visited.add(current);

      if (!this.borderTriangleIndices.includes(current)) {
        this.segmentedTriangles.add(current);

        segmentedTrianglesSize++;
        if (segmentedTrianglesSize / candidatesLength > 0.55) break;

        const neighbors = this.adjacencyMap.get(current) || [];
        neighbors.forEach((neighbor) => {
          if (
            !this.visited.has(neighbor) &&
            !this.borderTriangleIndices.includes(neighbor)
          ) {
            queue.push(neighbor);
          }
        });
      }
    }
  }
}
